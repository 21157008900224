import MDBox from 'components/MDBox'
import { Button, Form, Input, Modal } from 'antd'
import { useState } from 'react'
import { authService } from '../../redux/services'
import { useDispatch } from 'react-redux'
import { authActions } from '../../redux/actions'
import { useForm } from 'antd/es/form/Form'
import { min } from 'moment'

function ChangePassword() {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        isLoading: false,
    })
    const [form] = useForm()

    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    const onSubmit = (values) => {
        updateState('isLoading', true)

        if (values.new_password !== values.confirm_password) {
            updateState('isLoading', false)

            form.resetFields()

            return Modal.error({
                title: 'Ops',
                content: 'As senhas não conferem',
            })
        }

        authService
            .changePassword(values)
            .then((response) => {
                updateState('isLoading', false)

                dispatch(authActions.refreshUserData())
            })
            .catch((error) => {
                updateState('isLoading', false)
                return Modal.error({
                    title: 'Ops',
                    content: String(error),
                })
            })
    }

    return (
        <div className="page-login">
            <div className="container-login">
                <div className="container-logo">
                    <img
                        src={require('../../assets/images/logo-ct.png')}
                        alt=""
                    />
                    <h3
                        style={{
                            textAlign: 'center',
                            color: '#fff',
                            marginTop: 20,
                        }}
                    >
                        Informe sua nova senha
                    </h3>
                </div>
                <div className="side-login">
                    <Form
                        form={form}
                        initialValues={{
                            new_password: '',
                            confirm_password: '',
                        }}
                        layout="vertical"
                        onFinish={onSubmit}
                    >
                        <Form.Item
                            name="new_password"
                            rules={[
                                { required: true },
                                {
                                    min: 8,
                                    message:
                                        'A senha deve conter pelo menos 8 caracteres',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Nova senha" />
                        </Form.Item>

                        <Form.Item
                            name="confirm_password"
                            rules={[
                                { required: true },
                                {
                                    min: 8,
                                    message:
                                        'A senha deve conter pelo menos 8 caracteres',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Confirme a nova senha" />
                        </Form.Item>

                        <MDBox mt={4} mb={1}>
                            <Button
                                loading={state.isLoading}
                                type="primary"
                                block
                                htmlType="submit"
                            >
                                ALTERAR SENHA
                            </Button>
                        </MDBox>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword
