import React, { Component } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { Menu } from "antd";
import menus from "config/menus";
import { authActions } from "redux/actions";

const SubMenu = Menu.SubMenu;

class MainNavigation extends Component {
    static propTypes = {
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: () => null,
    };

    render() {
        const { router: { location }, userData } = this.props;
        let base = "";
        let defaultOpenKeys = [];
        let selectedKeys = [];
        let paths = location.pathname.split("/").filter(function (el) {
            return el;
        });

        let pathsGroups = "";

        paths.forEach((path, index) => {
            if (path) {
                if (index === 0) {
                    base = `/${path}`;
                }

                pathsGroups += `/${path}`;

                defaultOpenKeys.push(pathsGroups);
            }
        });

        selectedKeys.push(location.pathname);
        selectedKeys.push(base);

        const renderRoutes = menus[userData.role]?.map(({ name, icon, key, path, children }) => {
            if (children) {
                return (
                    <SubMenu key={path} title={name} icon={icon}>
                        {children.map(item => (
                            <Menu.Item style={{ height: 40 }} key={item.path}>
                                <NavLink to={item.path}>
                                    {item.name}
                                </NavLink>
                            </Menu.Item>
                        ))}
                    </SubMenu>
                )
            }

            return (
                <Menu.Item key={path} icon={icon}>
                    <NavLink to={path}>
                        {name}
                    </NavLink>
                </Menu.Item>
            )
        })

        return (
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={selectedKeys}
                defaultOpenKeys={defaultOpenKeys}
                selectedKeys={selectedKeys}
                onClick={this.props.onClick}
            >
                {renderRoutes}
                <Menu.Item style={{ position: "absolute", bottom: 10 }} key="/logout" icon={<i className="fal fa-sign-out" />}>
                    <NavLink onClick={this.props.doLogout}>
                        Sair
                    </NavLink>
                </Menu.Item>
            </Menu>
        )
    }
}



const mapStateToProps = (state, ownProps) => {
    return {
        permissions: state.auth.userData.permissions,
        userData: state.auth.userData,
    };
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        doLogout: () => dispatch(authActions.logout())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(MainNavigation);
