import { api } from "./../../config/api";

const basePath = "dashboard";

/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAll = (options) => {
    const options_default = {}

    // Merge config
    options = Object.assign({}, options_default, options)

    let params = []
    let params_qs = ''

    if (options.hasOwnProperty('signed')) {
        params.push(`signed=${options.signed}`)
    }

    if (options.hasOwnProperty('user_id')) {
        params.push(`user_id=${options.user_id}`)
    }

    if (options.hasOwnProperty('created_at')) {
        options.created_at.forEach((item, index) => {
            params.push(`created_at[${index}]=${item}`)
        })
    }

    if (options.hasOwnProperty('stage_id')) {
        params.push(`stage_id=${options.stage_id}`)
    }

    if (options.hasOwnProperty('service_id')) {
        params.push(`service_id=${options.service_id}`)
    }

    if (params.length) {
        params_qs = `?${params.join('&')}`
    }

    return api.get(`${basePath}${params_qs}`)
};
