export default {
	hashed: false,
	components: {
		Button: {
			fontWeight: 800
		},
		Progress: {
			circleTextColor: "#fff"
		},
	},
	token: {
		controlHeight: 50,
		colorPrimary: "#c69e62",
		borderRadius: 10,
		motion: true,

	}
}