import { api } from '../../config/api'

const basePath = 'payments'

/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAll = (options) => {
    const options_default = {}

    // Merge config
    options = Object.assign({}, options_default, options)

    let params = []
    let params_qs = ''

    if (options.hasOwnProperty('page')) {
        params.push(`page=${options.page}`)
    }

    if (options.hasOwnProperty('limit')) {
        params.push(`limit=${options.limit}`)
    }

    if (options.hasOwnProperty('search')) {
        params.push(`search=${options.search}`)
    }

    if (options.hasOwnProperty('orderBy')) {
        params.push(`orderBy=${options.orderBy}`)
    }

    if (options.hasOwnProperty('received')) {
        params.push(`received=${options.received}`)
    }

    if (options.hasOwnProperty('is_active')) {
        params.push(`is_active=${options.is_active}`)
    }

    if (options.hasOwnProperty('id') && options.id) {
        params.push(`id=${options.id}`)
    }

    if (options.hasOwnProperty('exportItems')) {
        params.push(`exportItems=${options.exportItems}`)
    }

    if (options.hasOwnProperty('created_at')) {
        options.created_at.forEach((item, index) => {
            params.push(`created_at[${index}]=${item}`)
        })
    }

    if (params.length) {
        params_qs = `?${params.join('&')}`
    }

    return api.get(`${basePath}${params_qs}`, {
        responseType: options.exportItems ? 'blob' : 'json',
    })
}

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const create = (options) => {
    return api.post(`${basePath}`, options)
}

/**
 * Edit
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
    return api.put(`${basePath}/${options.id}`, options)
}

/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const show = (options) => {
    return api.get(`${basePath}/${options.id}`)
}

/**
 * Delete
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const destroy = (options) => {
    return api.delete(`${basePath}/${options.id}`)
}
