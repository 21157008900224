import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Alert, Col, Form, Input, Modal, Row, Switch } from "antd";

import { franchiseeService, userService } from "./../../redux/services";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Password extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isSending: false,
			item: null,
			passwordRandom: true,
		};
	}

	onOpen = (item) => {
		this.setState({
			item: item,
		});
	};

	resetFields = () => {
		this.form.resetFields();

		this.setState({
			passwordRandom: true
		});
	};

	onClose = () => {
		if (this.state.isSending) {
			return false;
		}

		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = () => {
		this.form.validateFields().then((values) => {
			this.setState({
				isSending: true
			});

			const { item } = this.state;

			const data = {
				id: item.id,
			};

			userService.resendPassword(data)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					Modal.error({
						title: "Ocorreu um erro!",
						content: data.error_message,
					});
				});
		}).catch((data) => {
			//
		});
	};

	render() {
		const { visible } = this.props;
		const { isSending, passwordRandom } = this.state;

		return (
			<Modal
				open={visible}
				title="Recriar senha"
				destroyOnClose={true}
				maskClosable={false}
				width={450}
				okText={isSending ? "Enviando" : "Enviar"}
				cancelButtonProps={{ disabled: isSending }}
				onCancel={this.onClose}
				onOk={this.onSubmit}
				confirmLoading={isSending}>
				<Form
					ref={el => this.form = el}
					layout="vertical"
					initialValues={{
						password_random: passwordRandom,
					}}>
					<Alert message="O usuário irá receber a nova senha por e-mail." type="info" showIcon icon={<i className="fas fa-info-circle" />} style={{ marginBottom: 20 }} />
				</Form>
			</Modal>
		)
	}
}

export default Password;
