import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Button, Col, Form, Modal, Row, Tabs } from 'antd'

import moment from 'moment'

import {
    leadsService,
} from '../../redux/services'

import { UIDrawerForm } from '../../components'

const config = {
    externalName: 'franqueado',
}

class Show extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        external: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            id: 0,
            item: {},
        }
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
            item: {},
        })

        leadsService
            .show({ id })
            .then((response) => {
                let item = response.data

                this.setState({
                    isLoading: false,
                    item: item,
                })
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                    onOk: () => {
                        // Force close
                        return this.onClose()
                    },
                })
            })
    }

    resetFields = () => {
        this.setState({
            item: {},
        })
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }


    onOpenWhatsapp = (phone) => {
        phone = phone.replace(/\D/g, '')
        return window.open('https://wa.me/+55' + phone, '_blank')
    }


    render() {
        const { visible } = this.props
        const { id, isLoading, item } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                showBtnSave={false}
                title={`Visualizar ${this.props.external ? config.externalName : 'registro'
                    } [${id}]`}
            >
                <Form layout="vertical">
                    <Tabs defaultActiveKey="general">
                        <Tabs.TabPane
                            forceRender
                            tab="Info. Gerais"
                            key="general"
                        >
                            <Row gutter={16}>
                                <Col xs={24} sm={24}>
                                    <Form.Item label="Nome">
                                        {item.name ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="E-mail">
                                        {item.email ?? '-'}
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Telefone">
                                        <Button
                                            icon={<i className="fab fa-whatsapp"></i>}
                                            type="link"
                                            onClick={() => this.onOpenWhatsapp(item.phone)}
                                            title={item.phone}
                                        >
                                            {item.phone}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Profissão">
                                        {item.ocupation ?? '-'}
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Contato via">
                                        {item.origin ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Mensagem">
                                        {item.message ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Criação">
                                        {moment(item.created_at).calendar()}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Última atualização">
                                        {moment(item.updated_at).calendar()}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Show
