import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
    message,
} from 'antd'

// import { accountDeletionReasonService } from "../../redux/services";
import { UIDrawerForm, UIInputMask } from '../../components'
import { customerService } from 'redux/services'
import MaskedInput from 'react-text-mask'
import { PatternFormat } from 'react-number-format'
import { taxRegimeService } from 'redux/services'
import { consultantService } from 'redux/services'
import axios from 'axios'
import { franchiseeService } from 'redux/services'
import { API_ERRO_TYPE_CANCEL } from 'config/general'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Create extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isSending: false,
            taxRegimes: [],
            errors: [],
            franchiseesLoading: false,
            franchisees: [],
            searchMode: "Franqueado",
            armorType: "Nao",
            searchingCompany: false
        }
    }

    onOpen = () => {
        taxRegimeService
            .getAll()
            .then((response) => {
                this.setState({
                    taxRegimes: response.data.data,
                    isLoading: false,
                })
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const data = { ...values }

        if (this.state.armorType === "Maion") {
            data.maion_customer = true
        }

        customerService
            .create(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro cadastrado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((error) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(error),
                })
            })
    }

    setFieldsError = ({ messages }) => {
        if (!messages?.errors) return

        this.setState({
            errors: messages.errors,
        })
    }

    getErrorFiels = (field) => {
        let fieldData = {}
        let hasError = false
        let message = ''

        fieldData = this.state.errors.find((err) => err.field === field)

        if (field) {
            if (fieldData) {
                hasError = true
                message = fieldData.message
            }
        }

        return {
            hasError,
            message,
        }
    }

    onSearch = (value) => {
        if (!value || value.length === 0) return

        this.setState({
            searchingCompany: true
        })

        customerService
            .consultCnpj({ cnpj: value })
            .then((response) => {
                const { data } = response

                this.form.setFieldsValue({
                    document: data.estabelecimento?.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
                    company_name:
                        data.estabelecimento?.nome_fantasia ??
                        data.razao_social,
                    corporate_name: data.razao_social,
                    contact_name: '',
                    phone:
                        data.estabelecimento?.ddd1 +
                        data.estabelecimento?.telefone1,
                    email: data.estabelecimento?.email,
                })

                this.setState({
                    searchingCompany: false
                })
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                    searchingCompany: false
                })

                this.resetFields()

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchFranchisees = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                franchiseesLoading: false,
                franchisees: [],
            })

            return false
        }

        this.setState({
            franchiseesLoading: true,
        })

        franchiseeService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    franchiseesLoading: false,
                    franchisees: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    franchiseesLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchConsultants = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                franchiseesLoading: false,
                franchisees: [],
            })

            return false
        }

        this.setState({
            franchiseesLoading: true,
        })

        consultantService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    franchiseesLoading: false,
                    franchisees: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    franchiseesLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { visible, userData } = this.props


        const {
            isLoading,
            isSending,
            franchiseesLoading,
            franchisees,
            taxRegimes,
            armorType,
            searchingCompany
        } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={500}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title="Inserir novo cliente"
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                    initialValues={{
                        is_active: true,
                        document_type: 'cpf',
                        blind_company: true
                    }}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="document"
                                label="CNPJ"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                                validateStatus={
                                    this.setFieldsError('document')?.hasError
                                }
                                help={this.setFieldsError('document')?.message}
                            >
                                <UIInputMask
                                    allowClear
                                    loading={searchingCompany}
                                    mask="00.000.000/0000-00"
                                    onSearch={this.onSearch}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="company_name"
                                label="Nome da empresa"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="corporate_name"
                                label="Razão social"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="contact_name"
                                label="Nome do responsável"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="phone"
                                label="Telefone"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <PatternFormat
                                    className="ant-input  ant-input-outlined"
                                    format="(##) #####-####"
                                    allowEmptyFormatting
                                    mask="_"
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="email"
                                label="E-mail"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                    {
                                        type: 'email',
                                    },
                                ]}
                            >
                                <Input type="email" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="tax_regime"
                                label="Regime tributário"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Selecione o Regime tributário"
                                >
                                    {taxRegimes.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {(userData.role === "admin" || userData.role === "collaborator_admin") && (
                            <Col xl={12} sm={12}>
                                <Form.Item
                                    label="Blindar cliente?"
                                    hasFeedback
                                >
                                    <Radio.Group
                                        value={this.state.armorType}
                                        onChange={e => this.setState({ armorType: e.target.value })}
                                        options={[
                                            {
                                                label: 'Não',
                                                value: "Nao",
                                            },
                                            {
                                                label: 'Maion',
                                                value: "Maion",
                                            },
                                            {
                                                label: 'Franqueado/Consultor',
                                                value: "Sim",
                                            },
                                        ]} />
                                </Form.Item>
                            </Col>
                        )}
                        {(userData.role === "franchisee") && (
                            <Col xl={12} sm={12}>
                                <Form.Item
                                    label="Blindar cliente?"
                                    hasFeedback
                                >
                                    <Radio.Group
                                        value={this.state.armorType}
                                        onChange={e => this.setState({ armorType: e.target.value })}
                                        options={[
                                            {
                                                label: 'Minha empresa',
                                                value: "my_company",
                                            },
                                            {
                                                label: 'Consultor',
                                                value: "Consultor",
                                            },
                                        ]} />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>

                    {this.state.armorType === "Sim" && (
                        <Row gutter={16}>
                            {(userData.role === "admin" || userData.role === "collaborator_admin") ? (
                                <Col xl={12} sm={12}>
                                    <Form.Item
                                        name="user_id"
                                        label={
                                            <Radio.Group
                                                value={this.state.searchMode}
                                                onChange={e => this.setState({ searchMode: e.target.value })}
                                                options={[
                                                    {
                                                        label: 'Franqueado',
                                                        value: "Franqueado",
                                                    },
                                                    {
                                                        label: 'Consultor',
                                                        value: "Consultor",
                                                    },
                                                ]} />
                                        }
                                        hasFeedback
                                    >
                                        {this.state.searchMode === "Maion" ? (
                                            <Input
                                                value="MaionTax"
                                                placeholder='Maion Tax'
                                                disabled
                                            />
                                        ) : (
                                            <Select
                                                filterOption={false}
                                                allowClear
                                                placeholder={`Pesquise o ${this.state.searchMode}`}
                                                notFoundContent={
                                                    franchiseesLoading ? (
                                                        <Spin
                                                            indicator={
                                                                <i className="fad fa-spinner-third fa-spin" />
                                                            }
                                                        />
                                                    ) : null
                                                }
                                                onSearch={this.state.searchMode === "Franqueado" ? this.fetchFranchisees : this.fetchConsultants}
                                                showSearch
                                            >
                                                {franchisees.map((item, index) => (
                                                    <Select.Option
                                                        key={index}
                                                        value={item.id}
                                                    >
                                                        {item.company_name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Form.Item
                                    name="blind_company"
                                    label="Deseja blindar cliente?"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            )}
                        </Row>
                    )}

                    {(userData.role === "franchisee" && this.state.armorType === "Consultor") && (
                        <Row gutter={16}>
                            <Col xl={12} sm={12}>
                                <Form.Item
                                    name="user_id"
                                    label="Consultor"
                                    hasFeedback
                                >
                                    <Select
                                        filterOption={false}
                                        allowClear
                                        placeholder={`Pesquise o consultor`}
                                        notFoundContent={
                                            franchiseesLoading ? (
                                                <Spin
                                                    indicator={
                                                        <i className="fad fa-spinner-third fa-spin" />
                                                    }
                                                />
                                            ) : null
                                        }
                                        onSearch={this.fetchConsultants}
                                        showSearch
                                    >
                                        {franchisees.map((item, index) => (
                                            <Select.Option
                                                key={index}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="obs"
                                label="Observação"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />
                    <Form.Item
                        name="is_active"
                        label="Ativo"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
                <Modal open={this.state.iFrame}>
                    <iframe src={this.state.iFrame} />
                </Modal>
            </UIDrawerForm>
        )
    }
}

export default Create
