import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Button, DatePicker, Form, Modal, Radio, Select, Spin } from 'antd'

import moment from 'moment'
import { UIDatePicker } from 'components'

class Filters extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.filtersClean = {
            is_active: null,
            created_at: null,
            paid: null,
            approved: null,
        }

        this.state = {
            filters: {
                ...this.filtersClean,
            },
            isLoading: true,
        }
    }

    onOpen = (filters) => {
        this.setState({
            filters: filters,
        })
    }

    cleanFilters = () => {
        this.setState(
            {
                filters: this.filtersClean,
            },
            () => {
                // Callback
                this.props.onComplete({ ...this.state.filters })
            }
        )
    }

    onClose = () => {
        // Callback
        this.props.onClose()
    }

    filtersOnConfirm = () => {
        // Callback
        this.props.onComplete({ ...this.state.filters })
    }

    setFilter = (name, value) => {
        this.setState((state) => ({
            filters: {
                ...state.filters,
                [name]: value,
            },
        }))
    }

    render() {
        const { visible } = this.props

        const { filters, isLoading } = this.state

        return (
            <Modal
                open={visible}
                title="Filtrar"
                centered={true}
                destroyOnClose={true}
                maskClosable={true}
                width={900}
                okText="Aplicar"
                onCancel={this.onClose}
                onOk={this.filtersOnConfirm}
                className="modal-filters"
                footer={[
                    <Button key="back" type="link" onClick={this.cleanFilters}>
                        Excluir filtros
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={this.filtersOnConfirm}
                    >
                        Aplicar
                    </Button>,
                ]}
            >
                <div className="filter-group">
                    <div className="filter-group-title">
                        <h3>Status</h3>
                    </div>
                    <div
                        className="filter-group-filters"
                        style={{ paddingBottom: 0 }}
                    >
                        <Form.Item>
                            <Select
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (typeof option.children === 'string'
                                        ? option.children
                                        : option.children.props.children
                                    )
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear
                                placeholder="Selecione o status"
                                notFoundContent={
                                    isLoading ? (
                                        <Spin
                                            indicator={
                                                <i className="fad fa-spinner-third fa-spin" />
                                            }
                                        />
                                    ) : null
                                }
                                onChange={(value) =>
                                    this.setFilter('paid', value ?? null)
                                }
                                showSearch
                                value={this.state.filters.paid}
                            >
                                <Select.Option value={1}>Pago</Select.Option>
                                <Select.Option value={0}>
                                    Não pago
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className="filter-group">
                    <div className="filter-group-title">
                        <h3>Aprovado</h3>
                    </div>
                    <div
                        className="filter-group-filters"
                        style={{ paddingBottom: 0 }}
                    >
                        <Form.Item>
                            <Select
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (typeof option.children === 'string'
                                        ? option.children
                                        : option.children.props.children
                                    )
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear
                                placeholder="Selecione o status"
                                notFoundContent={
                                    isLoading ? (
                                        <Spin
                                            indicator={
                                                <i className="fad fa-spinner-third fa-spin" />
                                            }
                                        />
                                    ) : null
                                }
                                onChange={(value) =>
                                    this.setFilter('approved', value ?? null)
                                }
                                showSearch
                                value={this.state.filters.approved}
                            >
                                <Select.Option value={1}>
                                    Aprovado
                                </Select.Option>
                                <Select.Option value={0}>
                                    Não aprovado
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className="filter-group">
                    <div className="filter-group-title">
                        <h3>Criação</h3>
                    </div>
                    <div
                        className="filter-group-filters"
                        style={{ paddingBottom: 0 }}
                    >
                        <Form.Item>
                            <UIDatePicker.RangePicker
                                format="DD/MM/YYYY"
                                value={filters.created_at}
                                onChange={(date, dateString) =>
                                    this.setFilter('created_at', date ?? null)
                                }
                                disabledDate={(currentDate) =>
                                    currentDate.isAfter(moment(), 'day')
                                }
                            />
                        </Form.Item>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default Filters
