import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { ConfigProvider } from 'antd';

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

import { MaterialUIControllerProvider } from "context";
import { persistor, store } from "./redux/store/configureStore";
import theme from "./config/theme";
import pt_BR from 'antd/es/locale/pt_BR';
import "./sass/app.scss"

const container = document.getElementById("app");
const root      = createRoot(container);

root.render(
	<BrowserRouter>
		<MaterialUIControllerProvider>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ConfigProvider theme={theme} locale={pt_BR}>
						<App />
					</ConfigProvider>
				</PersistGate>
			</Provider>
		</MaterialUIControllerProvider>
	</BrowserRouter>
);

