import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Divider, Form, Modal, Row, Switch, Tabs } from 'antd'

import moment from 'moment'

import { orderService } from '../../redux/services'

import { UIDrawerForm } from '../../components'
import { number_format } from '../../helpers/phpjs'
import Finances from './Finances'
import Commissions from './Commissions'

const config = {
    externalName: 'franqueado',
}

class Show extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        external: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            id: 0,
            item: {},
        }
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
            item: {},
        })

        orderService
            .show({ id })
            .then((response) => {
                let item = response.data

                this.setState({
                    isLoading: false,
                    item: item,
                })
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                    onOk: () => {
                        // Force close
                        return this.onClose()
                    },
                })
            })
    }

    resetFields = () => {
        this.setState({
            item: {},
        })
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    render() {
        const { visible } = this.props
        const { id, isLoading, item } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={500}
                onClose={this.onClose}
                isLoading={isLoading}
                showBtnSave={false}
                title={`Visualizar ${this.props.external ? config.externalName : 'registro'
                    } [${id}]`}
            >
                <Form layout="vertical">
                    <Tabs defaultActiveKey="general">
                        <Tabs.TabPane
                            forceRender
                            tab="Info. Gerais"
                            key="general"
                        >
                            <Row gutter={16}>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Cliente">
                                        {item.customer?.corporate_name ?? '-'}
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="CNPJ">
                                        {item.customer?.document ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Regime tributário">
                                        {item.customer?.tax?.name ?? '-'}
                                    </Form.Item>
                                </Col>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Serviço">
                                        {item.service?.name ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Franqueado">
                                        {item.franchisee?.name ?? '-'}
                                    </Form.Item>
                                </Col>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Honorário %">
                                        {item.honorary
                                            ? `${number_format(item.honorary)}%`
                                            : '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Crédito estimado">
                                        {item.estimated_credit
                                            ? `R$ ${number_format(item.estimated_credit, 2, ',', '.')}`
                                            : '-'}
                                    </Form.Item>
                                </Col>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Crédito apurado">
                                        {item.credit
                                            ? `R$ ${number_format(item.credit, 2, ',', '.')}`
                                            : '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Previsão de conclusão">
                                        {item.completion_forecast
                                            ? moment(
                                                item.completion_forecast
                                            ).calendar()
                                            : '-'}
                                    </Form.Item>
                                </Col>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Etapa">
                                        {item.stage?.name ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Tipo de lançamento">
                                        {item.orderType?.name ?? '-'}
                                    </Form.Item>
                                </Col>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Time">
                                        {item.team?.name ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Assinado">
                                        <Switch
                                            checked={item.signed}
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Assinado em">
                                        {item.signed_at
                                            ? moment(item.signed_at).calendar()
                                            : '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xl={12} sm={12}>
                                    <Form.Item label="Concluído em" hasFeedback>
                                        {item.finish_at
                                            ? moment(item.finish_at).calendar()
                                            : '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xl={24} sm={24}>
                                    <Form.Item label="Observação">
                                        {item.obs ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Criação">
                                        {moment(item.created_at).calendar()}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Última atualização">
                                        {moment(item.updated_at).calendar()}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Conta corrente" key="payments">
                            <Finances id={id} item={item} />
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane tab="Comissões" key="commissions">
                            <Commissions id={id} item={item} />
                        </Tabs.TabPane> */}
                    </Tabs>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Show
