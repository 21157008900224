export default {
	LOGIN: "AUTH_LOGIN",

	LOGOUT: "AUTH_LOGOUT",

	USERDATA_REQUEST: "AUTH_USERDATA_REQUEST",
	USERDATA_SUCCESS: "AUTH_USERDATA_SUCCESS",
	USERDATA_ERROR  : "AUTH_USERDATA_ERROR",

	UPDATE_AVATAR: "AUTH_UPDATE_AVATAR",
};
