import { UILayout } from 'components'
import React, { Component } from 'react'

class Development extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <UILayout>
                <h2 style={{ textAlign: "center" }}>Pagina em desenvolvimento</h2>
            </UILayout>
        )
    }
}

export default Development