import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Upload,
} from 'antd'

import { UIDrawerForm } from './../../../components'
import { classeService } from 'redux/services'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Edit extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isSending: false,
            id: 0,
            data: {},
            value: 0,
            fileList: [],
        }

        this._axiosCancelToken = null
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
        })

        classeService.show({ id })
            .then((response) => {
                this.setState(
                    {
                        isLoading: false,
                        data: response.data,
                    },
                    () => {
                        this.fillForm(response.data)
                    }
                )
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fillForm = (data) => {
        this.form.setFieldsValue({
            name: data.name,
            order: data.order,
            description: data.description,
        })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const { id } = this.state

        const data = {
            ...values,
            value: this.state.value,
        }
        // id
        data.id = id

        classeService
            .edit(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro atualizado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { visible } = this.props

        const {
            id,
            isLoading,
            isSending,
            fileList
        } = this.state

        const props = {
            onRemove: (file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                this.setState({
                    fileList: newFileList,
                });
            },
            beforeUpload: (file) => {
                this.setState({
                    fileList: [...fileList, file],
                });
                return false;
            },
            fileList,
        };

        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title={`Editar registro [${id}]`}
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="file"
                                label="Video"
                                hasFeedback
                            >
                                <Upload  {...props}>
                                    <Button icon={<i className="fas fa-cloud-upload"></i>}>Upload</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="name"
                                label="Título do video"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                rules={[{ required: true }]}
                                name="order"
                                label="Ordem"
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                rules={[{ required: true }]}
                                name="description"
                                label="Descrição"
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Edit
