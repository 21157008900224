import React, { Component, Fragment } from 'react'
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar'

import { UILayout, UIPageListing } from '../../components'
import { Button, Dropdown, Menu, Modal, Spin, Tag } from 'antd'
import { connect } from 'react-redux'
import { generalActions } from '../../redux/actions'
import { serviceService } from '../../redux/services'
import moment from 'moment'

import ModalCreate from './create'
import ModalShow from './show'
import ModalEdit from './edit'
import ModalFilters from './filters'

const config = {
    title: 'Serviços',
    permissionPrefix: 'services',
    list: 'services',
    searchPlaceholder: 'Buscar por nome',
    orders: [
        {
            label: 'Mais recentes',
            field: 'id',
            sort: 'desc',
            default: true,
        },
        {
            label: 'Mais antigos',
            field: 'id',
            sort: 'asc',
        },
    ],
}

class Services extends Component {
    constructor(props) {
        super(props)

        const defaultOrder = config.orders.find((o) => o.default)

        this.state = {
            isLoading: false,
            listType: 'list',
            data: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            orderByLabel: defaultOrder.label,
            orderByField: defaultOrder.field,
            orderBySort: defaultOrder.sort,
            search: '',
            // Actions
            createModalVisible: false,
            showModalVisible: false,
            exportModalVisible: false,
            filtersModalVisible: false,
            activeLoadings: [],
            isExporting: false,
            // Filters
            totalFilters: 0,
            filters: {
                is_active: null,
                created_at: null,
            },
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.listType && state.listType !== props.listType) {
            return {
                listType: props.listType,
            }
        }

        return null
    }

    componentDidMount() {
        // Fecth all
        this.fetchGetAll(true)
    }

    menuItem = (item) => (
        <Menu className="actions-dropdown-menu">
            <Menu.Item key="show">
                <a onClick={() => this.showOpen(item)}>
                    <i className="fal fa-file" />
                    Visualizar
                </a>
            </Menu.Item>
            <Menu.Item key="edit">
                <a onClick={() => this.editOpen(item)}>
                    <i className="fal fa-pen" />
                    Editar
                </a>
            </Menu.Item>
            <Menu.Item key="activate/deactivate" className="divider">
                <a
                    onClick={() =>
                        this.activateDeactivate(item, !item.is_active)
                    }
                >
                    {item.is_active ? (
                        <i className="fal fa-eye-slash" />
                    ) : (
                        <i className="fal fa-eye" />
                    )}
                    {item.is_active ? 'Desativar' : 'Ativar'}
                </a>
            </Menu.Item>
            {/* <Menu.Item key="delete" className="divider btn-delete">
                <a onClick={() => this.deleteConfirm(item)}>
                    <i className="fal fa-trash" />
                    Excluir
                </a>
            </Menu.Item> */}
        </Menu>
    )

    columns = () => {
        const listTypeCard = this.state.listType === 'card'

        return [
            {
                title: 'ID',
                className: 'id',
                width: 50,
                render: (item) => <span title={item.id}>{item.id}</span>,
            },
            {
                title: 'Nome',
                render: (item) => <span title={item.name}>{item.name}</span>,
            },
            {
                title: 'Criação',
                className: 'datetime card-block-width-2',
                render: (item) => {
                    if (listTypeCard) {
                        return (
                            <Fragment>
                                <i
                                    className="fal fa-plus-circle"
                                    style={{ marginRight: 5 }}
                                />
                                {moment(item.created_at).format(
                                    'DD/MM/YYYY HH:mm'
                                )}
                            </Fragment>
                        )
                    }

                    return moment(item.created_at).format('DD/MM/YYYY HH:mm')
                },
            },
            {
                title: 'Últ. modificação',
                className: 'datetime card-block-width-2',
                render: (item) => {
                    if (listTypeCard) {
                        return (
                            <Fragment>
                                <i
                                    className="fal fa-pen"
                                    style={{ marginRight: 5 }}
                                />
                                {moment(item.updated_at).format(
                                    'DD/MM/YYYY HH:mm'
                                )}
                            </Fragment>
                        )
                    }

                    return moment(item.updated_at).format('DD/MM/YYYY HH:mm')
                },
            },
            {
                title: 'Ativo',
                className: 'active no-ellipsis',
                render: (item) =>
                    this.state.activeLoadings.indexOf(item.id) !== -1 ? (
                        <Spin
                            indicator={
                                <i className="fad fa-spinner-third fa-spin" />
                            }
                        />
                    ) : (
                        <Tag color={item.is_active ? '#0acf97' : '#fa5c7c'}>
                            {item.is_active ? 'Ativo' : 'Inativo'}
                        </Tag>
                    ),
            },
            {
                title: 'Ações',
                className: 'actions no-ellipsis',
                // visible  : this.props.permissions.includes(config.permissionPrefix + ".show") || this.props.permissions.includes(config.permissionPrefix + ".edit") || this.props.permissions.includes(config.permissionPrefix + ".delete"),
                visible: true,
                render: (item) => (
                    <Dropdown
                        overlay={this.menuItem(item)}
                        className="actions-dropdown"
                        placement="bottomRight"
                        trigger={['click']}
                    >
                        <Button icon={<i className="fal fa-ellipsis-v" />} />
                    </Dropdown>
                ),
            },
        ]
    }

    fetchGetAll = (init = false, exportItems = false) => {
        const { pagination, orderByField, orderBySort, search, filters } =
            this.state

        if (exportItems) {
            this.setState({
                isExporting: true,
            })
        } else {
            this.setState({
                isLoading: true,
            })
        }

        const data = {
            orderBy: `${orderByField}:${orderBySort}`,
            search: search,
        }

        if (exportItems) {
            data.exportItems = true
        } else {
            data.page = init ? 1 : pagination.current
            data.limit = pagination.pageSize
        }

        if (filters.is_active !== null) {
            data.is_active = filters.is_active
        }

        if (filters.created_at) {
            data.created_at = [
                filters.created_at[0]
                    .clone()
                    .startOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
                filters.created_at[1]
                    .clone()
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
            ]
        }

        serviceService
            .getAll(data)
            .then((response) => {
                if (exportItems) {
                    this.setState({
                        isExporting: false,
                    })

                    window.open(response.data.file_url, '_blank')
                } else {
                    this.setState((state) => ({
                        isLoading: false,
                        data: response.data.data,
                        pagination: {
                            ...state.pagination,
                            current: response.data.meta.current_page,
                            total: response.data.meta.total,
                        },
                    }))
                }
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    onListTypeChange = (type) => {
        this.props.onChangeListType(type)
    }

    onPaginationChange = (page) => {
        this.setState(
            (state) => ({
                pagination: {
                    ...state.pagination,
                    current: page,
                },
            }),
            () => {
                this.fetchGetAll()
            }
        )
    }

    onOrderChange = (value) => {
        const defaultOrder = config.orders.find(
            (o) => `${o.field}:${o.sort}` === value
        )

        if (!defaultOrder) return null

        this.setState(
            (state) => ({
                orderByLabel: defaultOrder.label,
                orderByField: defaultOrder.field,
                orderBySort: defaultOrder.sort,
            }),
            () => {
                this.fetchGetAll(true)
            }
        )
    }

    onSearch = (value) => {
        this.setState(
            {
                search: value,
            },
            () => {
                this.fetchGetAll(true)
            }
        )
    }

    onSearchChange = (e) => {
        // If it does not have type then it's cleaning
        if (!e.hasOwnProperty('type')) {
            const { search } = this.state

            this.setState(
                {
                    search: e.target.value,
                },
                () => {
                    if (search) {
                        this.fetchGetAll(true)
                    }
                }
            )
        }
    }

    /**
     * Show
     *
     * @param id
     */
    showOpen = ({ id }) => {
        this.setState({ showModalVisible: true })

        // On open screen
        this.showScreen.onOpen(id)
    }

    showOnClose = () => {
        this.setState({ showModalVisible: false })
    }

    /**
     * Delete
     *
     * @param id
     */
    deleteConfirm = ({ id }) => {
        Modal.confirm({
            title: 'Confirmar exclusão!',
            content: 'Tem certeza de que deseja excluir este registro?',
            okText: 'Excluir',
            autoFocusButton: null,
            onOk: () => {
                return this.deleteConfirmed(id)
            },
        })
    }

    deleteConfirmed = (id) => {
        return accountDeletionReasonService
            .destroy({ id })
            .then((response) => {
                // Fecth all
                this.fetchGetAll()
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    /**
     * Create
     */
    createOpen = () => {
        this.setState({ createModalVisible: true })

        // On open screen
        this.createScreen.onOpen()
    }

    createOnClose = () => {
        this.setState({ createModalVisible: false })
    }

    createOnComplete = () => {
        this.setState({ createModalVisible: false })

        // Fecth all
        this.fetchGetAll(true)
    }

    /**
     * Edit
     *
     * @param id
     */
    editOpen = ({ id }) => {
        this.setState({ editModalVisible: true })

        // On open screen
        this.editScreen.onOpen(id)
    }

    editOnClose = () => {
        this.setState({ editModalVisible: false })
    }

    editOnComplete = () => {
        this.setState({ editModalVisible: false })

        // Fecth all
        this.fetchGetAll()
    }

    /**
     * Filter
     */
    filtersOpen = () => {
        this.setState({ filtersModalVisible: true })

        // On open screen
        this.filtersScreen.onOpen({ ...this.state.filters })
    }

    filtersOnClose = () => {
        this.setState({ filtersModalVisible: false })
    }

    filtersOnComplete = (filters) => {
        this.setState({ filtersModalVisible: false })

        this.setState(
            {
                totalFilters: Object.keys(filters).filter(
                    (key) =>
                        filters.hasOwnProperty(key) && filters[key] !== null
                ).length,
                filters: filters,
            },
            () => {
                // Fecth all
                this.fetchGetAll(true)
            }
        )
    }

    /**
     * Active/Desactive
     *
     * @param {number} id
     * @param {boolean} activate
     */
    activateDeactivate = ({ id }, activate) => {
        const { activeLoadings } = this.state

        if (activeLoadings.indexOf(id) === -1) {
            activeLoadings.push(id)
        }

        this.setState({
            activeLoadings: activeLoadings,
        })

        serviceService
            .edit({ id, is_active: activate })
            .then((response) => {
                const newData = [...this.state.data]
                const index = newData.findIndex((item) => id === item.id)

                if (index !== -1) {
                    const item = newData[index]

                    newData.splice(index, 1, {
                        ...item,
                        is_active: response.data.is_active,
                    })

                    this.setState({
                        data: newData,
                    })
                }
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
            .finally(() => {
                const { activeLoadings } = this.state
                const index = activeLoadings.indexOf(id)

                if (index !== -1) {
                    activeLoadings.splice(index, 1)

                    this.setState({
                        activeLoadings: activeLoadings,
                    })
                }
            })
    }

    render() {
        const { data } = this.state

        return (
            <UILayout>
                <DashboardNavbar absolute isMini title={config.title} />
                <div className="page page-franchisees">
                    <UIPageListing
                        onSearch={this.onSearch}
                        onSearchChange={this.onSearchChange}
                        onPaginationChange={this.onPaginationChange}
                        onOrderChange={this.onOrderChange}
                        onListTypeChange={this.onListTypeChange}
                        onFiltersClick={this.filtersOpen}
                        isLoading={this.state.isLoading}
                        listType={this.state.listType}
                        orderByField={this.state.orderByField}
                        orderBySort={this.state.orderBySort}
                        orders={config.orders}
                        search={this.state.search}
                        searchPlaceholder={config.searchPlaceholder}
                        data={this.state.data}
                        pagination={this.state.pagination}
                        columns={this.columns()}
                        showFilters={true}
                        totalFilters={this.state.totalFilters}
                        buttons={[
                            {
                                visible: true,
                                onClick: this.createOpen,
                                title: 'Cadastrar',
                                icon: <i className="far fa-plus" />,
                            },
                        ]}
                    />
                </div>
                <ModalShow
                    ref={(el) => (this.showScreen = el)}
                    visible={this.state.showModalVisible}
                    onClose={this.showOnClose}
                />
                <ModalCreate
                    ref={(el) => (this.createScreen = el)}
                    visible={this.state.createModalVisible}
                    onComplete={this.createOnComplete}
                    onClose={this.createOnClose}
                />
                <ModalFilters
                    ref={(el) => (this.filtersScreen = el)}
                    visible={this.state.filtersModalVisible}
                    onComplete={this.filtersOnComplete}
                    onClose={this.filtersOnClose}
                />
                <ModalEdit
                    ref={(el) => (this.editScreen = el)}
                    visible={this.state.editModalVisible}
                    onComplete={this.editOnComplete}
                    onClose={this.editOnClose}
                />
            </UILayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        permissions: state.auth.userData.permissions,
        listType: state.general.listType[config.list],
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onChangeListType: (type) => {
            dispatch(generalActions.changeListType(config.list, type))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services)
