import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
	Col,
	Divider,
	Form,
	Input,
	message,
	Modal,
	Row,
	Switch,
} from 'antd'

import { teamService } from '../../redux/services'

import { UIDrawerForm } from '../../components'
import moment from 'moment'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			isLoading: true,
			isSending: false,
			id: 0,
			documentType: 'cpf',
		}

		this._axiosCancelToken = null
	}

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id: id,
		})

		teamService
			.show({ id })
			.then((response) => {
				let item = response.data

				this.setState(
					{
						isLoading: false,
					},
					() => {
						// Fill form
						this.fillForm(item)
					}
				)
			})
			.catch((data) => {
				Modal.error({
					title: 'Ocorreu um erro!',
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose()
					},
				})
			})
	}

	fillForm = (data) => {
		this.form.setFieldsValue({
			name: data.name,
			is_active: data.is_active
		})
	}

	resetFields = () => {
		this.form.resetFields()
	}

	onClose = () => {
		// Reset fields
		this.resetFields()

		// Callback
		this.props.onClose()
	}

	onFinish = (values) => {
		this.setState({
			isSending: true,
		})

		const { id } = this.state

		const data = {
			...values,
			birth: moment(values.birth).format('YYYY-MM-DD'),
		}

		// id
		data.id = id

		teamService
			.edit(data)
			.then((response) => {
				this.setState({
					isSending: false,
				})

				// Reset fields
				this.resetFields()

				// Success message
				message.success('Registro atualizado com sucesso.')

				// Callback
				this.props.onComplete()
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				})

				Modal.error({
					title: 'Ocorreu um erro!',
					content: String(data),
				})
			})
	}

	render() {
		const { visible } = this.props

		const { id, isLoading, isSending, documentType } = this.state

		const documentMask =
			documentType === 'cpf' ? '###.###.###-##' : '##.###.###/####-##'

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar registro [${id}]`}
			>
				<Form
					ref={(el) => (this.form = el)}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						password_random: true,
						is_active: true,
					}}
				>
					<Row gutter={16}>
						<Col xl={24} sm={24}>
							<Form.Item
								name="name"
								label="Nome"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Divider />
					<Row gutter={16}>
						<Col xl={12} sm={12}>
							<Form.Item
								name="is_active"
								label="Ativo"
								valuePropName="checked"
							>
								<Switch />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Edit
