import permissions from './permissions'


const defaultsMenus = [
    {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        icon: <i className="fas fa-tachometer-alt-slow"></i>,
        path: '/',
    },
    {
        type: 'collapse',
        name: 'Clientes',
        key: 'clientes',
        icon: <i className="far fa-user-shield"></i>,
        path: '/clientes',
    },
    {
        type: 'collapse',
        name: 'Gestão',
        key: 'orders',
        icon: <i className="fas fa-usd-square"></i>,
        path: '/gestao',
    },
]

const admin = [
    {
        type: 'collapse',
        name: 'Dashboard',
        key: '/',
        icon: <i className="fas fa-tachometer-alt-slow"></i>,
        path: '/',
    },
    {
        type: 'collapse',
        name: 'Franqueados',
        key: '/franqueados',
        icon: <i className="fas fa-user-tie"></i>,
        path: '/franqueados',
    },
    {
        type: 'collapse',
        name: 'Consultores',
        key: '/consultores',
        icon: <i className="fas fa-user"></i>,
        path: '/consultores',
    },
    {
        type: 'collapse',
        name: 'Colaboradores',
        key: '/colaboradores',
        icon: <i className="far fa-users"></i>,
        path: '/colaboradores',
    },
    {
        type: 'collapse',
        name: 'Clientes',
        key: '/clientes',
        icon: <i className="far fa-user-shield"></i>,
        path: '/clientes',
    },
    {
        type: 'collapse',
        name: 'Serviços',
        key: '/servicos',
        icon: <i className="far fa-hand-holding-usd"></i>,
        path: '/servicos',
    },
    {
        type: 'collapse',
        name: 'Times',
        key: '/times',
        icon: <i className="fad fa-users-class"></i>,
        path: '/times',
    },
    {
        type: 'collapse',
        name: 'Unidades',
        key: '/unidades',
        icon: <i className="fad fa-building"></i>,
        path: '/unidades',
    },
    {
        type: 'collapse',
        name: 'Gestão',
        key: '/gestao',
        icon: <i className="fas fa-usd-square"></i>,
        path: '/gestao',
    },
    {
        type: 'collapse',
        name: 'Finanças',
        key: '/financas',
        icon: <i className="fal fa-cash-register"></i>,
        path: '/financas',
    },
    {
        type: 'collapse',
        name: 'Treinamentos',
        key: '/treinamento',
        icon: < i className="far fa-users-class" ></i >,
        path: '/treinamento',
    },
    {
        type: 'collapse',
        name: 'Gerenciar site',
        key: '/gestao/site',
        icon: <i className="far fa-browser"></i>,
        path: '/gestao/site',
        children: [
            {
                type: 'menu',
                name: 'Blog',
                key: '/site/blog',
                path: '/site/blog',
            },
            {
                type: 'menu',
                name: 'Leads',
                key: '/site/leads',
                path: '/site/leads',
            }
        ]
    },
]

const franchisee = [
    {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        icon: <i className="fas fa-tachometer-alt-slow"></i>,
        path: '/',
    },
    {
        type: 'collapse',
        name: 'Consultores',
        key: 'consultants',
        icon: <i className="fas fa-user"></i>,
        path: '/consultores',
    },
    {
        type: 'collapse',
        name: 'Colaboradores',
        key: 'colaboradores',
        icon: <i className="far fa-users"></i>,
        path: '/colaboradores',
    },
    {
        type: 'collapse',
        name: 'Clientes',
        key: 'clientes',
        icon: <i className="far fa-user-shield"></i>,
        path: '/clientes',
    },
    {
        type: 'collapse',
        name: 'Gestão',
        key: 'orders',
        icon: <i className="fas fa-usd-square"></i>,
        path: '/gestao',
    },
    {
        type: 'collapse',
        name: 'Treinamentos',
        key: 'training',
        icon: <i className="far fa-users-class"></i>,
        path: '/treinamentos',
    },
]

const consultant = [
    {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        icon: <i className="fas fa-tachometer-alt-slow"></i>,
        path: '/',
    },
    {
        type: 'collapse',
        name: 'Clientes',
        key: 'clientes',
        icon: <i className="far fa-user-shield"></i>,
        path: '/clientes',
    },
    {
        type: 'collapse',
        name: 'Gestão',
        key: 'orders',
        icon: <i className="fas fa-usd-square"></i>,
        path: '/gestao',
    },
]

const collaborator = [
    {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        icon: <i className="fas fa-tachometer-alt-slow"></i>,
        path: '/',
    },
    {
        type: 'collapse',
        name: 'Clientes',
        key: 'clientes',
        icon: <i className="far fa-user-shield"></i>,
        path: '/clientes',
    },
    {
        type: 'collapse',
        name: 'Gestão',
        key: 'orders',
        icon: <i className="fas fa-usd-square"></i>,
        path: '/gestao',
    },
]

const collaborator_admin = [
    {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        icon: <i className="fas fa-tachometer-alt-slow"></i>,
        path: '/',
    },
    {
        type: 'collapse',
        name: 'Clientes',
        key: 'clientes',
        icon: <i className="far fa-user-shield"></i>,
        path: '/clientes',
    },
    {
        type: 'collapse',
        name: 'Gestão',
        key: 'orders',
        icon: <i className="fas fa-usd-square"></i>,
        path: '/gestao',
    },
    {
        type: 'collapse',
        name: 'Treinamentos',
        key: 'training',
        icon: <i className="far fa-users-class"></i>,
        path: '/treinamentos',
    },
]

export default {
    admin,
    franchisee,
    consultant,
    collaborator,
    collaborator_admin
}
