import { api } from "./../../config/api";

const basePath = "webservice";

/**
 * Find cep
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const findZipcode = (options) => {
	return api.get(`${basePath}/zipcode/${options.zipcode}`);
};
