import React, { Component, Fragment } from 'react'
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar'

import { UILayout, UIPageListing } from '../../../components'
import {
    Button,
    Dropdown,
    Menu,
    Modal,
    Row,
    Spin,
    Tag,
    Statistic,
    Card,
    Flex,
} from 'antd'
import { connect } from 'react-redux'
import { generalActions } from 'redux/actions'

import ModalCreate from './create'
import ModalShow from './show'
import ModalEdit from './edit'
import ModalFilters from './filters'
import { classeService } from 'redux/services'
import { VIDEO_URL } from 'config/general'
const config = {
    title: 'Aulas',
    permissionPrefix: 'classes',
    list: 'classes',
    searchPlaceholder: 'Buscar por nome',
    orders: [
        {
            label: 'Mais recentes',
            field: 'order',
            sort: 'desc',
            default: true,
        },
        {
            label: 'Mais antigos',
            field: 'order',
            sort: 'asc',
        },
    ],
}

class Customers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
        }

        const defaultOrder = config.orders.find((o) => o.default)

        this.state = {
            isLoading: false,
            listType: 'list',
            data: [],
            total_received: 0,
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            orderByLabel: defaultOrder.label,
            orderByField: defaultOrder.field,
            orderBySort: defaultOrder.sort,
            search: '',
            // Actions
            createModalVisible: false,
            showModalVisible: false,
            editModalVisible: false,
            stageModalVisible: false,
            exportModalVisible: false,
            filtersModalVisible: false,
            activeLoadings: [],
            isExporting: false,
            // Filters
            totalFilters: 0,
            filters: {
                is_active: null,
                created_at: null,
                client_id: null,
                property_id: null,
                cultivation_id: null,
            },
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.listType && state.listType !== props.listType) {
            return {
                listType: props.listType,
            }
        }

        return null
    }

    componentDidMount() {
        // Fecth all
        this.fetchGetAll(true)
    }

    menuItem = (item) => (
        <Menu className="actions-dropdown-menu">
            {(this.props.userData.role === 'admin' || this.props.userData.role === "collaborator_admin") && (
                <Menu.Item key="edit">
                    <a onClick={() => this.editOpen(item)}>
                        <i className="fal fa-pen" />
                        Editar
                    </a>
                </Menu.Item>
            )}
            {(this.props.userData.role === 'admin' || this.props.userData.role === "collaborator_admin") && (
                <Menu.Item key="delete" className="divider btn-delete">
                    <a onClick={() => this.deleteConfirm(item)}>
                        <i className="fal fa-trash" />
                        Excluir
                    </a>
                </Menu.Item>
            )}
        </Menu>
    )

    columns = () => {
        const listTypeCard = this.state.listType === 'card'

        return [
            {
                title: 'ID',
                className: 'id',
                width: 50,
                render: (item) => <span title={item.id}>{item.id}</span>,
            },
            // {
            //     title: 'Ordem',
            //     className: 'order',
            //     width: 100,
            //     render: (item) => <span title={item.order}>{item.order}</span>,
            // },
            {
                title: 'Nome',
                render: (item) => (
                    <span title={item.name}>
                        {item.name}
                    </span>
                ),
            },
            {
                title: 'Video URL',
                render: (item) => (
                    <a href={VIDEO_URL + item.video_url} target='_blank' title={VIDEO_URL + item.video_url}>
                        {VIDEO_URL + item.video_url}
                    </a>
                ),
            },
            {
                title: 'Ações',
                className: 'actions no-ellipsis',
                // visible  : this.props.permissions.includes(config.permissionPrefix + ".show") || this.props.permissions.includes(config.permissionPrefix + ".edit") || this.props.permissions.includes(config.permissionPrefix + ".delete"),
                visible: true,
                render: (item) => (
                    <Dropdown
                        overlay={this.menuItem(item)}
                        className="actions-dropdown"
                        placement="bottomRight"
                        trigger={['click']}
                    >
                        <Button icon={<i className="fal fa-ellipsis-v" />} />
                    </Dropdown>
                ),
            },
        ]
    }

    fetchGetAll = (init = false, exportItems = false) => {
        const { item } = this.props
        const { pagination, orderByField, orderBySort, search, filters } =
            this.state

        if (exportItems) {
            this.setState({
                isExporting: true,
            })
        } else {
            this.setState({
                isLoading: true,
            })
        }

        const data = {
            orderBy: `${orderByField}:${orderBySort}`,
            search: search,
            id: this.props.id,
        }

        if (exportItems) {
            data.exportItems = true
        } else {
            data.page = init ? 1 : pagination.current
            data.limit = pagination.pageSize
        }

        if (filters.is_active !== null) {
            data.is_active = filters.is_active
        }

        if (filters.created_at) {
            data.created_at = [
                filters.created_at[0]
                    .clone()
                    .startOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
                filters.created_at[1]
                    .clone()
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
            ]
        }

        classeService
            .getAll(data)
            .then((response) => {
                if (exportItems) {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    )

                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Conta_corrente_${item.customer.company_name
                        }_${new Date().getTime()}.pdf`
                    )

                    document.body.appendChild(link)
                    link.click()

                    document.body.removeChild(link)

                    this.setState({
                        isExporting: false,
                    })
                } else {
                    this.setState((state) => ({
                        isLoading: false,
                        data: response.data.data,
                        total_received: response.data.meta.total_received,
                        pagination: {
                            ...state.pagination,
                            current: response.data.meta.current_page,
                            total: response.data.meta.total,
                        },
                    }))
                }
            })
            .catch((data) => {
                this.setState({
                    isExporting: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    onListTypeChange = (type) => {
        this.props.onChangeListType(type)
    }

    onPaginationChange = (page) => {
        this.setState(
            (state) => ({
                pagination: {
                    ...state.pagination,
                    current: page,
                },
            }),
            () => {
                this.fetchGetAll()
            }
        )
    }

    onOrderChange = (value) => {
        const defaultOrder = config.orders.find(
            (o) => `${o.field}:${o.sort}` === value
        )

        if (!defaultOrder) return null

        this.setState(
            (state) => ({
                orderByLabel: defaultOrder.label,
                orderByField: defaultOrder.field,
                orderBySort: defaultOrder.sort,
            }),
            () => {
                this.fetchGetAll(true)
            }
        )
    }

    onSearch = (value) => {
        this.setState(
            {
                client_name: value,
            },
            () => {
                this.fetchGetAll(true)
            }
        )
    }

    onSearchChange = (e) => {
        // If it does not have type then it's cleaning
        if (!e.hasOwnProperty('type')) {
            const { search } = this.state

            this.setState(
                {
                    search: e.target.value,
                },
                () => {
                    if (search) {
                        this.fetchGetAll(true)
                    }
                }
            )
        }
    }

    /**
     * Show
     *
     * @param id
     */
    showOpen = ({ id }) => {
        this.setState({ showModalVisible: true })

        // On open screen
        this.showScreen.onOpen(id)
    }

    showOnClose = () => {
        this.setState({ showModalVisible: false })
    }

    /**
     * Delete
     *
     * @param id
     */
    deleteConfirm = ({ id }) => {
        Modal.confirm({
            title: 'Confirmar exclusão!',
            content: 'Tem certeza de que deseja excluir este registro?',
            okText: 'Excluir',
            autoFocusButton: null,
            onOk: () => {
                return this.deleteConfirmed(id)
            },
        })
    }

    deleteConfirmed = (id) => {
        return classeService.destroy({ id })
            .then((response) => {
                // Fecth all
                this.fetchGetAll()
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    /**
     * Create
     */
    createOpen = () => {
        this.setState({ createModalVisible: true })

        // On open screen
        this.createScreen.onOpen({ id: this.props.id })
    }

    createOnClose = () => {
        this.setState({ createModalVisible: false })
    }

    createOnComplete = () => {
        this.setState({ createModalVisible: false })

        // Fecth all
        this.fetchGetAll(true)
    }

    /**
     * Edit
     *
     * @param id
     */
    editOpen = ({ id }) => {
        this.setState({ editModalVisible: true })

        // On open screen
        this.editScreen.onOpen(id)
    }

    editOnClose = () => {
        this.setState({ editModalVisible: false })
    }

    editOnComplete = () => {
        this.setState({ editModalVisible: false })

        // Fecth all
        this.fetchGetAll()
    }

    /**
     * Filter
     */
    filtersOpen = () => {
        this.setState({ filtersModalVisible: true })

        // On open screen
        this.filtersScreen.onOpen({ ...this.state.filters })
    }

    filtersOnClose = () => {
        this.setState({ filtersModalVisible: false })
    }

    filtersOnComplete = (filters) => {
        this.setState({ filtersModalVisible: false })

        this.setState(
            {
                totalFilters: Object.keys(filters).filter(
                    (key) =>
                        filters.hasOwnProperty(key) && filters[key] !== null
                ).length,
                filters: filters,
            },
            () => {
                // Fecth all
                this.fetchGetAll(true)
            }
        )
    }

    /**
     * Receive payment
     *
     * @param {number} id
     * @param {boolean} activate
     */
    onReceive = ({ id }) => {
        const { activeLoadings } = this.state

        if (activeLoadings.indexOf(id) === -1) {
            activeLoadings.push(id)
        }

        this.setState({
            activeLoadings: activeLoadings,
        })

        paymentService
            .edit({ id, received: true })
            .then((response) => {
                const newData = [...this.state.data]
                const index = newData.findIndex((item) => id === item.id)

                if (index !== -1) {
                    const item = newData[index]

                    newData.splice(index, 1, {
                        ...item,
                        received: response.data.received,
                    })

                    this.setState(
                        {
                            data: newData,
                        },
                        () => {
                            this.fetchGetAll()
                        }
                    )
                }
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
            .finally(() => {
                const { activeLoadings } = this.state
                const index = activeLoadings.indexOf(id)

                if (index !== -1) {
                    activeLoadings.splice(index, 1)

                    this.setState({
                        activeLoadings: activeLoadings,
                    })
                }
            })
    }

    render() {
        const { item, userData } = this.props
        const { total_received } = this.state

        const enableRegister = userData.role === 'admin'

        return (
            <Fragment>
                <UIPageListing
                    onSearchChange={this.onSearchChange}
                    onPaginationChange={this.onPaginationChange}
                    onOrderChange={this.onOrderChange}
                    onListTypeChange={this.onListTypeChange}
                    onFiltersClick={this.filtersOpen}
                    isLoading={this.state.isLoading}
                    listType={this.state.listType}
                    orderByField={this.state.orderByField}
                    orderBySort={this.state.orderBySort}
                    orders={config.orders}
                    data={this.state.data}
                    pagination={this.state.pagination}
                    columns={this.columns()}
                    showFilters={false}
                    totalFilters={this.state.totalFilters}
                    showListTypeChange={false}
                    enableSearch={false}
                    buttons={[
                        {
                            visible: enableRegister,
                            onClick: this.createOpen,
                            title: 'Cadastrar',
                            icon: <i className="far fa-plus" />,
                        },
                    ]}
                />
                <ModalShow
                    ref={(el) => (this.showScreen = el)}
                    visible={this.state.showModalVisible}
                    onClose={this.showOnClose}
                />
                <ModalCreate
                    ref={(el) => (this.createScreen = el)}
                    visible={this.state.createModalVisible}
                    onComplete={this.createOnComplete}
                    onClose={this.createOnClose}
                    userData={this.props.userData}
                    id={this.props.id}
                />
                <ModalFilters
                    ref={(el) => (this.filtersScreen = el)}
                    visible={this.state.filtersModalVisible}
                    onComplete={this.filtersOnComplete}
                    onClose={this.filtersOnClose}
                />
                <ModalEdit
                    ref={(el) => (this.editScreen = el)}
                    visible={this.state.editModalVisible}
                    onComplete={this.editOnComplete}
                    onClose={this.editOnClose}
                    userData={this.props.userData}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        permissions: state.auth.userData.permissions,
        listType: state.general.listType[config.list],
        userData: state.auth.userData,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onChangeListType: (type) => {
            dispatch(generalActions.changeListType(config.list, type))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers)
