import React, { Component } from 'react'
import axios from 'axios'
import * as PropTypes from 'prop-types'
import {
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
} from 'antd'

import {
    franchiseeService,
    orderService,
    stageService,
} from './../../redux/services'

import moment from 'moment'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Edit extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isSending: false,
            id: 0,
            stages: [],
            current_stage: null,
            stage_id: null,
        }

        this._axiosCancelToken = null
    }

    onOpen = (item) => {
        this.setState(
            {
                isLoading: true,
                id: item.id,
            },
            () => {
                stageService
                    .getAll()
                    .then((response) => {
                        this.setState(
                            {
                                isLoading: false,
                                stages: response.data,
                                current_stage: item.stage_id,
                                stage_id: item.stage_id,
                            },
                            () => {
                                this.form.setFieldsValue({
                                    stage_id: item.stage_id,
                                })
                            }
                        )
                    })
                    .catch((data) => {
                        Modal.error({
                            title: 'Ocorreu um erro!',
                            content: String(data),
                        })
                    })
            }
        )
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const data = {
            ...values,
        }

        // id
        data.id = this.state.id

        orderService
            .edit(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro atualizado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { visible } = this.props

        const { id, isLoading, isSending, stages } = this.state

        return (
            <Modal
                open={visible}
                width={500}
                onCancel={this.onClose}
                closable
                isLoading={isLoading}
                isSending={isSending}
                title={`Editar etapa [${id}]`}
                onOk={() => this.form.submit()}
                okButtonProps={{
                    disabled: this.state.current_stage === this.state.stage_id,
                    loading: this.state.isSending,
                }}
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="stage_id"
                                label="Etapa"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Etapa"
                                    value={this.state.stage_id}
                                    onChange={(e) => this.setState({ current_stage: e })}
                                >
                                    {stages.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="stage_comment"
                                label="Comentário"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default Edit
