import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
} from 'antd'

import {
    franchiseeService,
    orderService,
    serviceService,
    stageService,
} from 'redux/services'

import { UIDatePicker, UIDrawerForm } from './../../../components'
import UIInputNumber from 'components/InputNumber'
import moment from 'moment'
import { NumericFormat } from 'react-number-format'
import { paymentService } from 'redux/services'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Edit extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isSending: false,
            id: 0,
            data: {},
            value: 0
        }

        this._axiosCancelToken = null
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
        })

        paymentService.show({ id })
            .then((response) => {
                this.setState(
                    {
                        isLoading: false,
                        data: response.data,
                    },
                    () => {
                        this.fillForm(response.data)
                    }
                )
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fillForm = (data) => {
        this.form.setFieldsValue({
            value: data.value,
            received: data.received,
            received_at: moment(data.received_at),
        })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const { id } = this.state

        const data = {
            ...values,
            value: this.state.value,
        }
        // id
        data.id = id

        paymentService
            .edit(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro atualizado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { visible } = this.props

        const {
            id,
            isLoading,
            isSending,
        } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title={`Editar registro [${id}]`}
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="value"
                                label="Valor da compensação/restituição"
                                hasFeedback
                            >
                                <NumericFormat
                                    className="ant-input ant-input-outlined"
                                    prefix="R$"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    value={this.state.value}
                                    onValueChange={({ floatValue }) => {
                                        this.setState({
                                            value: floatValue,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="received_at"
                                label="Data de compensação"
                            >
                                <UIDatePicker
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="received"
                                label="Deseja marcar como recebido?"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="paid"
                                label="Deseja criar comissão ao franqueado referente a esse pagamento?"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row> */}
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Edit
