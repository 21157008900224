import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
} from 'antd'

import {
    franchiseeService,
    orderService,
    serviceService,
    stageService,
} from 'redux/services'

import { UIDatePicker, UIDrawerForm } from './../../../components'
import UIInputNumber from 'components/InputNumber'
import moment from 'moment'
import { NumericFormat } from 'react-number-format'
import { connect } from 'react-redux'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Edit extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isSending: false,
            id: 0,
            data: {},
            documentType: 'cpf',
            customersLoading: false,
            customers: [],
            franchiseesLoading: false,
            franchisees: [],
            services: [],
            stages: [],

            honorary: 0,
            estimated_credit: null,
            credit: null,
        }

        this._axiosCancelToken = null
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
        })

        Promise.all([serviceService.getAll(), stageService.getAll()])
            .then(([serviceResponse, stageResponse]) => {
                this.setState(
                    {
                        stages: stageResponse.data,
                    },
                    () => {
                        orderService
                            .show({ id })
                            .then((response) => {
                                this.setState(
                                    {
                                        isLoading: false,
                                        data: response.data,
                                    },
                                    () => {
                                        this.fillForm(response.data)
                                    }
                                )
                            })
                            .catch((data) => {
                                Modal.error({
                                    title: 'Ocorreu um erro!',
                                    content: String(data),
                                })
                            })
                    }
                )
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fillForm = (data) => {
        this.form.setFieldsValue({
            stage_id: data.stage_id,
            service_id: data.service.name,
            customer_id: data.customer.company_name,
            user_id: data.franchisee.company_name,
            honorary: data.honorary,
            signed: data.signed,
            estimated_credit: data.estimated_credit,
            credit: data.credit,
            completion_forecast: data.completion_forecast
                ? moment(data.completion_forecast)
                : null,
            finish_at: data.finish_at ? moment(data.finish_at) : null,
            obs: data.obs,
        })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const { id } = this.state

        const data = {
            ...values,
            completion_forecast: moment(values.completion_forecast),
            finish_at: moment(values.finish_at),
            estimated_credit: this.state.estimated_credit,
            credit: this.state.credit,
            honorary: this.state.honorary,
        }

        delete data.customer_id
        delete data.service_id
        delete data.user_id

        // id
        data.id = id

        orderService
            .edit(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro atualizado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { visible, userData } = this.props

        const {
            id,
            isLoading,
            isSending,
            customers,
            customersLoading,
            services,
            stages,
            franchiseesLoading,
            franchisees,
        } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title={`Editar registro [${id}]`}
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="customer_id"
                                label="Cliente"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="service_id"
                                label="Serviço"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="stage_id"
                                label="Etapa"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Etapa"
                                >
                                    {stages.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        {userData.role === 'admin' && (
                            <Col xl={12} sm={12}>
                                <Form.Item
                                    name="user_id"
                                    label="Franqueado"
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Campo obrigatório.',
                                        },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        )}
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="honorary"
                                label="Honorário %"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <NumericFormat
                                    className="ant-input ant-input-outlined"
                                    suffix="%"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    value={this.state.honorary}
                                    max={100}
                                    min={1}
                                    onValueChange={({ floatValue }) => {
                                        this.setState({
                                            honorary: floatValue,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="estimated_credit"
                                label="Crédito estimado"
                                hasFeedback
                            >
                                <NumericFormat
                                    className="ant-input ant-input-outlined"
                                    prefix="R$"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    value={this.state.estimated_credit}
                                    onValueChange={({ floatValue }) => {
                                        this.setState({
                                            estimated_credit: floatValue,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="credit"
                                label="Crédito apurado"
                                hasFeedback
                            >
                                <NumericFormat
                                    className="ant-input ant-input-outlined"
                                    prefix="R$"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    value={this.state.credit}
                                    onValueChange={({ floatValue }) => {
                                        this.setState({
                                            credit: floatValue,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="completion_forecast"
                                label="Previsão de conclusão"
                                hasFeedback
                            >
                                <UIDatePicker
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY"
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="finish_at"
                                label="Concluído em"
                                hasFeedback
                            >
                                <UIDatePicker
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="obs"
                                label="Observação"
                                hasFeedback
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="signed"
                                label="Assinado"
                                valuePropName="checked"
                            >
                                <Switch disabled={this.state.data.signed} />
                            </Form.Item>
                        </Col>
                        {this.state.data.signed_at && (
                            <Col xl={12} sm={12}>
                                <Form.Item label="Assinado em">
                                    {moment(
                                        this.state.data.signed_at
                                    ).calendar()}
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form>
            </UIDrawerForm>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.auth.userData,
    }
}


export default connect(mapStateToProps, null)(Edit)
