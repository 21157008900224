import React, { useEffect, useMemo, useState } from 'react'

import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom'

import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'

import Configurator from 'examples/Configurator'

// Material Dashboard 2 React themes
import theme from 'assets/theme'

import themeDark from 'assets/theme-dark'

import rtlPlugin from 'stylis-plugin-rtl'
import createCache from '@emotion/cache'

import routes from './config/routes'
import menus from './config/menus'

import {
    setMiniSidenav,
    setOpenConfigurator,
    useMaterialUIController,
} from 'context'

import { useSelector } from 'react-redux'

import moment from 'moment'
import 'moment/locale/pt-br'

import NotFound from './layouts/NotFound'

import LoggedTemplate from 'templates/LoggedTemplate'
import ChangePassword from 'layouts/ChangePassword'
import DefaultTemplate from 'templates/defaultTemplate'

moment.locale('pt-br')

export default function App() {
    const [controller, dispatch] = useMaterialUIController()
    const { isAuthenticated } = useSelector((state) => state.auth)

    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller

    const [onMouseEnter, setOnMouseEnter] = useState(false)
    const [rtlCache, setRtlCache] = useState(null)
    const { pathname } = useLocation()

    // Cache for the rtl
    useMemo(() => {
        const cacheRtl = createCache({
            key: 'rtl',
            stylisPlugins: [rtlPlugin],
        })

        setRtlCache(cacheRtl)
    }, [])

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false)
            setOnMouseEnter(true)
        }
    }

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true)
            setOnMouseEnter(false)
        }
    }

    // Change the openConfigurator state
    const handleConfiguratorOpen = () =>
        setOpenConfigurator(dispatch, !openConfigurator)

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute('dir', direction)
    }, [direction])

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
    }, [pathname])

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse)
            }

            if (route.route) {
                return (
                    <Route
                        exact
                        path={route.route}
                        element={route.component}
                        key={route.key}
                    />
                )
            }

            return null
        })

    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === 'vr' && <Configurator />}
            <Routes>
                {routes.map((pathItem) => {
                    if (!pathItem.logged) {
                        return (
                            <Route
                                key={pathItem.key}
                                path={pathItem.path}
                                element={
                                    <SignInRouteWithRouter path={pathItem.path}>
                                        {pathItem.component}
                                    </SignInRouteWithRouter>
                                }
                            />
                        )
                    }

                    return (
                        <Route
                            key={pathItem.key}
                            path={pathItem.path}
                            element={
                                <ProtectedRouteWithRouter>
                                    <DefaultTemplate
                                        showSideNav={pathItem.showSideNav}
                                    >
                                        <ProtectedRouteWithRouter>
                                            {pathItem.component}
                                        </ProtectedRouteWithRouter>
                                    </DefaultTemplate>
                                </ProtectedRouteWithRouter>
                            }
                        />
                    )
                })}

                <Route path="*" element={<NotFound />} />
            </Routes>
        </ThemeProvider>
    )
}

const SignInRouteWithRouter = withRouter(SignInRoute)
const ProtectedRouteWithRouter = withRouter(ProtectedRoute)

function SignInRoute({ children, path, ...rest }) {
    const { isAuthenticated } = useSelector((state) => state.auth)

    if (isAuthenticated) {
        return <Navigate to="/" replace />
    }

    if (React.isValidElement(children)) {
        return React.cloneElement(children, { ...rest })
    }

    return children
}

function ProtectedRoute({ children, ...rest }) {
    const { isAuthenticated, userData } = useSelector((state) => state.auth)

    if (!isAuthenticated) {
        return <Navigate to="/entrar" replace />
    }

    if (!userData.password_changed) {
        return <ChangePassword />
    }

    if (React.isValidElement(children)) {
        return React.cloneElement(children, { ...rest })
    }

    return children
}

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation()
        let navigate = useNavigate()
        let params = useParams()
        return <Component {...props} router={{ location, navigate, params }} />
    }

    return ComponentWithRouterProp
}
