import React from 'react'
import { NumericFormat } from 'react-number-format'
import PropTypes from 'prop-types'
import { prefix } from 'stylis'

class UIInputNumber extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: 0,
        }
    }

    handleChange = ({ floatValue }) => {
        this.setState({ value: floatValue })
    }

    getValue = () => {
        return this.state.value
    }

    render() {
        const { ...props } = this.props

        return (
            <NumericFormat
                className="ant-input ant-input-outlined"
                thousandSeparator
                valueIsNumericString={true}
                value={this.state.value}
                onValueChange={this.handleChange}
                {...props}
            />
        )
    }
}

export default UIInputNumber
