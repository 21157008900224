import MDBox from 'components/MDBox'
import { Button, Form, Input, Modal } from 'antd'
import { useState } from 'react'
import { authService } from '../../redux/services'
import { Link } from 'react-router-dom'
import { apiUpdateAccessToken } from '../../config/api'
import { useDispatch } from 'react-redux'
import { authActions } from '../../redux/actions'

function Login() {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        isLoading: false,
    })

    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    const onSubmit = (values) => {
        let access_token

        updateState('isLoading', true)
        authService
            .login(values)
            .then((response) => {
                updateState('isLoading', false)

                access_token = response.data.token

                // Update access_token from api instance
                apiUpdateAccessToken(`Bearer ${access_token}`)

                return authService.getUserData()
            })
            .then((response) => {
                dispatch(
                    authActions.login({
                        access_token: access_token,
                        ...response.data,
                    })
                )
            })
            .catch((error) => {
                updateState('isLoading', false)
                return Modal.error({
                    title: 'Ops',
                    content: String(error),
                })
            })
    }

    return (
        <div className="page-login">
            <div className="container-login">
                <div className="container-logo">
                    <img
                        src={require('../../assets/images/logo-ct.png')}
                        alt=""
                    />
                </div>
                <div className="side-login">
                    <Form
                        autoComplete="none"
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        layout="vertical"
                        onFinish={onSubmit}
                    >
                        <Form.Item name="email" rules={[{ required: true }]}>
                            <Input type="email" placeholder="E-mail" />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true }]}>
                            <Input.Password placeholder="Senha" />
                        </Form.Item>

                        <MDBox mt={4} mb={1}>
                            <Button
                                loading={state.isLoading}
                                type="primary"
                                block
                                htmlType="submit"
                            >
                                ENTRAR
                            </Button>
                        </MDBox>
                        {/* <MDBox mt={2} mb={1}>
                            <Link to="/recuperar-senha">
                                Esqueci minha senha
                            </Link>
                        </MDBox> */}
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Login
