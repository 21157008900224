import { FlashAutoRounded } from '@mui/icons-material';
import { Button, Modal, Progress, Spin } from 'antd';
import RadialChart from 'components/RadialChart';
import { API_URL } from 'config/general';
import { Component } from 'react';
import { connect } from 'react-redux';
import { classeUserService } from 'redux/services';
import { trainingUserService } from 'redux/services';
import { trainingService } from 'redux/services';
import { Player } from 'video-react';

class Watching extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isMarkLoading: false,
            trainingId: 0,
            item: {
                classes: []
            },
            currentClasse: 0,
            trainingData: {},
            progress: 0
        }
    }

    componentDidMount() {
        this.fetchTraining()
    }

    fetchTraining = () => {
        const { params } = this.props.router

        this.setState({
            isLoading: true,
            id: params.id,
        })

        let item

        trainingService
            .show({ id: params.id })
            .then((response) => {
                item = response.data

                this.setState({
                    item: item,
                })

                return trainingUserService.show({ id: params.id })
            })
            .then((response) => {
                const total = item.classes.length
                const totalFinished = response.data.classes?.filter(item => item.finish).length

                const progress = (totalFinished * 100) / item.classes.length

                this.setState({
                    isLoading: false,
                    trainingData: response.data,
                    progress
                })
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    onPressPrevius = () => {
        if (this.state.currentClasse === 0) {
            return
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.setState(state => ({
                currentClasse: state.currentClasse - 1,
                isLoading: false,
            }))
        })
    }

    onPressNext = () => {
        if (this.state.currentClasse === (this.state.item?.classes?.length - 1)) {
            return
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.setState(state => ({
                currentClasse: state.currentClasse + 1,
                isLoading: false,
            }))
        })
    }

    onClickClasse = (index) => {
        this.setState({
            isLoading: true,
        }, () => {
            this.setState(state => ({
                currentClasse: index,
                isLoading: false,
            }))
        })
    }

    returnHasVisited = (classeId) => {
        const { trainingData } = this.state

        return trainingData.classes?.find(classe => classe.classe_id === classeId)?.finish
    }

    onMarkFinished = () => {
        const { item, currentClasse } = this.state
        const { params } = this.props.router

        this.setState({
            isMarkLoading: true,
        })

        const data = {
            classe_id: item.classes[currentClasse]?.id,
            training_id: params.id
        }

        classeUserService.markFinish(data)
            .then((response) => {
                return trainingUserService.show({ id: params.id })
            })
            .then((response) => {
                const totalFinished = response.data.classes?.filter(item => item.finish).length

                const progress = (totalFinished * 100) / item.classes.length

                this.setState({
                    isMarkLoading: false,
                    trainingData: response.data,
                    progress,
                })
            })
            .catch((data) => {
                this.setState({
                    isMarkLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { item, currentClasse, isLoading, isMarkLoading, progress } = this.state
        return (
            <div className='training-component' >
                <aside className='video-navigator'>
                    <div className='training-title'>
                        <h2>{item.name}</h2>
                        <Progress trailColor='#fff' size={50} percent={progress.toFixed(0)} type='circle' />
                    </div>
                    <ul>
                        {item.classes.map((classe, index) => (
                            <li key={index} >
                                <div onClick={() => this.onClickClasse(index)} className={`video-card ${classe.id === item.classes[currentClasse]?.id ? "active" : ""}`}>
                                    <div>
                                        <i className="fas fa-play"></i>
                                        <span>{classe.name}</span>
                                    </div>
                                    {this.returnHasVisited(classe.id) && <i className="fas fa-check-circle"></i>}
                                </div>
                            </li>
                        ))}
                    </ul>
                </aside>
                <div className='video-container'>
                    <div className="video">
                        {
                            isLoading ? (
                                <Spin />
                            ) : (
                                <>
                                    <Player fluid onEnded={this.onPressNext}>
                                        <source src={API_URL + "/classes/stream/" + item.classes[currentClasse]?.video_url} type='video/mp4' />
                                    </Player>
                                    <div className='buttons-container'>
                                        <div className='video-title'>
                                            <span>{item.classes[currentClasse]?.name}</span>
                                            <Button disabled={this.returnHasVisited(item.classes[currentClasse]?.id)} loading={isMarkLoading} icon={<i className="fas fa-check-circle"></i>} onClick={this.onMarkFinished}>Marcar como concluída</Button>
                                        </div>
                                        <div className='desciption-container'>
                                            <h3>Descrição</h3>
                                            <span>{item.classes[currentClasse]?.description}</span>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.auth.userData,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Watching)
