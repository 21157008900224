import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Card, Checkbox, Col, Form, Input, message, Modal, Row, Switch } from "antd";

import { classeService, permissionService, teamService, trainingService } from "./../../redux/services";

import { UIDrawerForm } from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			id: 0,
			allAccess: false
		};
	}

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id: id,
		});

		trainingService.show({ id })
			.then((response) => {
				this.setState(
					{
						isLoading: false,
						data: response.data,
					},
					() => {
						this.fillForm(response.data)
					}
				)
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		this.form.setFieldsValue({
			name: data.name,
			order: data.order,
		})
	}

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {

		this.setState({
			isSending: true,
		});

		const { id } = this.state;

		const data = { ...values };

		// id
		data.id = id;

		trainingService.edit(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro atualizado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onChangeAccess = (value) => {
		console.log(value)

		this.setState({
			allAccess: value,
		});
	}

	render() {
		const { visible } = this.props;
		const { id, isLoading, isSending, allAccess } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar registro [${id}]`}>
				<Form
					ref={(el) => (this.form = el)}
					id={formId}
					layout="vertical"
					onFinish={this.onFinish}>
					<Row gutter={16}>
						<Col xl={24} sm={24}>
							<Form.Item
								name="name"
								label="Título do treinamento"
							>
								<Input />
							</Form.Item>
						</Col>

					</Row>
					<Row gutter={16}>
						<Col xl={12} sm={12}>
							<Form.Item
								label="Todos tem acesso?"
							>
								<Switch onChange={this.onChangeAccess} checked={allAccess} />
							</Form.Item>
						</Col>
						{!allAccess && (
							<Col xl={12} sm={12}>
								<Form.Item
									name="users"
									label="Usuários com acesso"
								>
									<Input />
								</Form.Item>
							</Col>
						)}
					</Row>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Edit;
