import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Divider, Form, Input, Modal, Switch, message } from 'antd'

// import { accountDeletionReasonService } from "../../redux/services";
import { UIDrawerForm } from '../../components'
import { teamService } from 'redux/services'
import { serviceService } from 'redux/services'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Create extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isSending: false,
        }
    }

    onOpen = () => {
        this.setState({
            isLoading: false,
        })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const data = { ...values }

        serviceService
            .create(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro cadastrado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { visible } = this.props

        const { isLoading, isSending } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={500}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title="Inserir novo serviço"
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                    initialValues={{
                        password_random: true,
                        is_active: true,
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Nome"
                        hasFeedback
                        rules={[
                            { required: true, message: 'Campo obrigatório.' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Divider />
                    <Form.Item
                        name="is_active"
                        label="Ativo"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Create
