import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    message,
    Button,
    Upload,
    InputNumber,
    Progress
} from 'antd'

import { UIDrawerForm } from '../../../components'

import { classeService } from 'redux/services'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Create extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isSending: false,
            value: 0,
            id: 0,
            fileList: [],
            uploadProgress: 0
        }
    }

    onOpen = ({ id }) => {
        this.setState({
            id: id,
        })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        const { fileList } = this.state
        const { id } = this.props

        if (!fileList.length) {
            Modal.warning({
                title: 'Atenção!',
                content: "O video é obrigatorio",
            })

            return
        }

        this.setState({
            isSending: true,
        })


        const data = {
            ...values,
            training_id: id,
            file: fileList[0]
        }

        classeService
            .create(data, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({
                        uploadProgress: progress
                    })
                },
            })
            .then((response) => {
                this.setState({
                    isSending: false,
                    fileList: []
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro cadastrado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    onChange(props) {
        console.log(props)
    }

    render() {
        const { visible } = this.props

        const { isLoading, isSending, fileList, uploadProgress } = this.state

        const props = {
            onRemove: (file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                this.setState({
                    fileList: newFileList,
                });
            },
            beforeUpload: (file) => {
                this.setState({
                    fileList: [...fileList, file],
                });
                return false;
            },
            fileList,
        };

        return (
            <UIDrawerForm
                visible={visible}
                width={500}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title="Inserir nova aula"
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="file"
                                label="Video"
                                hasFeedback
                            >
                                <Upload  {...props}>
                                    <Button icon={<i className="fas fa-cloud-upload"></i>}>Upload</Button>
                                </Upload>
                                {uploadProgress > 0 && <Progress percent={uploadProgress} type="line" />}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="name"
                                label="Título do video"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="order"
                                label="Ordem"
                                rules={[{ required: true }]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                rules={[{ required: true }]}
                                name="description"
                                label="Descrição"
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Create
