import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
    message,
} from 'antd'

import { IMaskInput } from 'react-imask'

import { UIDrawerForm, } from '../../components'
import { customerService } from 'redux/services'
import { NumericFormat } from 'react-number-format'
import axios from 'axios'
import { serviceService } from 'redux/services'
import { stageService } from 'redux/services'
import { orderService } from 'redux/services'
import { franchiseeService } from 'redux/services'
import { teamService } from 'redux/services'
import { orderTypeService } from 'redux/services'
import { consultantService } from 'redux/services'
import { API_ERRO_TYPE_CANCEL } from 'config/general'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Create extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isSending: false,
            taxRegimes: [],
            customersLoading: false,
            customers: [],
            franchiseesLoading: false,
            teamsLoading: false,
            franchisees: [],
            services: [],
            stages: [],
            teams: [],
            orderTypes: [],
            loadingCustomer: false,
            customer: null,
            honorary: 0,
            searchMode: this.props.userData.role === "franchisee" ? "my_company" : "Franqueado"
        }
    }

    onOpen = () => {
        const options = {
            is_active: true
        }

        Promise.all([
            serviceService.getAll(options),
            stageService.getAll(options),
            teamService.getAll(options),
            orderTypeService.getAll(options),
        ])
            .then(
                ([
                    serviceResponse,
                    stageResponse,
                    teamResponse,
                    orderTypeResponse,
                ]) => {
                    this.setState({
                        isLoading: false,
                        services: serviceResponse.data.data,
                        stages: stageResponse.data,
                        teams: teamResponse.data.data,
                        orderTypes: orderTypeResponse.data,
                    })
                }
            )
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        const { customer } = this.state

        this.setState({
            isSending: true,
        })

        const data = { ...values, honorary: this.state.honorary }

        if (!values.team_id) {
            data.team_id = 1
        }

        orderService
            .create(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro cadastrado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchCustomers = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                customersLoading: false,
                customers: [],
            })

            return false
        }

        this.setState({
            customersLoading: true,
        })

        customerService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    customersLoading: false,
                    customers: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    customersLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchFranchisees = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                franchiseesLoading: false,
                franchisees: [],
            })

            return false
        }

        this.setState({
            franchiseesLoading: true,
        })

        franchiseeService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    franchiseesLoading: false,
                    franchisees: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    franchiseesLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchConsultants = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                franchiseesLoading: false,
                franchisees: [],
            })

            return false
        }

        this.setState({
            franchiseesLoading: true,
        })

        consultantService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    franchiseesLoading: false,
                    franchisees: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    franchiseesLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchTeams = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                teamsLoading: false,
                teams: [],
            })

            return false
        }

        this.setState({
            teamsLoading: true,
        })

        teamService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    teamsLoading: false,
                    teams: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    teamsLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    onSelectCustomer = (id) => {
        if (!id) {
            this.resetFields()

            return
        }

        this.setState({
            loadingCustomer: true,
        }, () => {
            customerService.show({ id })
                .then((response) => {
                    this.setState({
                        loadingCustomer: false,
                        customer: response.data,
                    })

                    const franchisee = response.data.user[0]

                    if (franchisee) {
                        this.setState({
                            franchisees: [
                                {
                                    ...franchisee
                                }
                            ]
                        }, () => {
                            if (this.props.userData.role === 'admin' || this.props.userData.role === "collaborator_admin") {
                                if (franchisee?.role === "consultant") {
                                    this.setState({
                                        searchMode: "Consultor",
                                    })
                                } else {
                                    this.setState({
                                        searchMode: "Franqueado"
                                    })
                                }

                                this.form.setFieldsValue({
                                    user_id: franchisee?.id ?? undefined,
                                })
                            }

                            if (this.props.userData.role === 'franchisee') {
                                if (franchisee?.id === this.props.userData.id) {
                                    this.setState({
                                        searchMode: "my_company",
                                    })
                                } else {
                                    this.setState({
                                        searchMode: "Consultor"
                                    })
                                }

                                this.form.setFieldsValue({
                                    user_id: franchisee?.id ?? undefined,
                                })
                            }
                        })
                    }
                })
                .catch((data) => {


                    Modal.error({
                        title: 'Ocorreu um erro!',
                        content: String(data),
                    })
                })
        })
    }

    render() {
        const { visible, userData } = this.props

        const {
            isLoading,
            isSending,
            customers,
            customersLoading,
            services,
            stages,
            franchiseesLoading,
            franchisees,
            teamsLoading,
            teams,
            orderTypes,
            loadingCustomer,
            customer
        } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={500}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title="Inserir nova oportunidade"
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                    initialValues={{
                        stage_id: 1,
                    }}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="customer_id"
                                label="Cliente"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Pesquise a cliente"
                                    notFoundContent={
                                        customersLoading ? (
                                            <Spin
                                                indicator={
                                                    <i className="fad fa-spinner-third fa-spin" />
                                                }
                                            />
                                        ) : null
                                    }
                                    onSearch={this.fetchCustomers}
                                    showSearch
                                    onChange={this.onSelectCustomer}
                                >
                                    {customers.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.corporate_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="service_id"
                                label="Serviço"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Serviço"
                                >
                                    {services.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="stage_id"
                                label="Etapa"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Etapa"
                                >
                                    {stages.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        {(userData.role === 'admin' || userData.role === "collaborator_admin") && (
                            <Col xl={12} sm={12}>
                                <Form.Item
                                    name="user_id"
                                    label={
                                        <Radio.Group
                                            value={this.state.searchMode}
                                            onChange={e => this.setState({ searchMode: e.target.value })}
                                            options={[
                                                {
                                                    label: 'Franqueado',
                                                    value: "Franqueado",
                                                },
                                                {
                                                    label: 'Consultor',
                                                    value: "Consultor",
                                                },
                                            ]} />
                                    }
                                    hasFeedback
                                >
                                    <Select
                                        filterOption={false}
                                        allowClear
                                        placeholder={`Pesquise o ${this.state.searchMode}`}
                                        notFoundContent={
                                            franchiseesLoading ? (
                                                <Spin
                                                    indicator={
                                                        <i className="fad fa-spinner-third fa-spin" />
                                                    }
                                                />
                                            ) : null
                                        }
                                        onSearch={this.state.searchMode === "Franqueado" ? this.fetchFranchisees : this.fetchConsultants}
                                        showSearch
                                        options={franchisees.map((item) => ({
                                            label: item.company_name,
                                            value: item.id,
                                            index: item.id
                                        }))}
                                    />
                                    {/* {franchisees.map((item, index) => (
                                            <Select.Option
                                                key={index}
                                                value={item.id}
                                            >
                                                {item.company_name}
                                            </Select.Option>
                                        ))} */}
                                    {/* </Select> */}
                                </Form.Item>
                            </Col>
                        )}

                        {(userData.role === "franchisee") && (
                            <Col xl={12} sm={12}>
                                <Form.Item
                                    name="user_id"
                                    label={
                                        <Radio.Group
                                            value={this.state.searchMode}
                                            onChange={e => this.setState({ searchMode: e.target.value })}
                                            options={[
                                                {
                                                    label: 'Minha empresa',
                                                    value: "my_company",
                                                },
                                                {
                                                    label: 'Consultor',
                                                    value: "Consultor",
                                                },
                                            ]} />
                                    }
                                    hasFeedback
                                >
                                    <Select
                                        filterOption={false}
                                        allowClear
                                        placeholder={`Pesquise o Consultor`}
                                        disabled={this.state.searchMode === "my_company"}
                                        notFoundContent={
                                            franchiseesLoading ? (
                                                <Spin
                                                    indicator={
                                                        <i className="fad fa-spinner-third fa-spin" />
                                                    }
                                                />
                                            ) : null
                                        }
                                        onSearch={this.state.searchMode === "Franqueado" ? this.fetchFranchisees : this.fetchConsultants}
                                        showSearch
                                        options={franchisees.map((item) => ({
                                            label: item.name,
                                            value: item.id,
                                            index: item.id
                                        }))}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="honorary"
                                label="Honorário %"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <NumericFormat
                                    className="ant-input ant-input-outlined"
                                    suffix="%"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    value={this.state.honorary}
                                    onValueChange={({ floatValue }) => {
                                        this.setState({
                                            honorary: floatValue,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        {(userData.role === 'admin' || userData.role === "collaborator_admin") && (
                            <Col xl={12} sm={12}>
                                <Form.Item
                                    name="team_id"
                                    label="Time"
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Campo obrigatório.',
                                        },
                                    ]}
                                >
                                    <Select
                                        filterOption={false}
                                        allowClear
                                        placeholder="Pesquise o time"
                                        notFoundContent={
                                            teamsLoading ? (
                                                <Spin
                                                    indicator={
                                                        <i className="fad fa-spinner-third fa-spin" />
                                                    }
                                                />
                                            ) : null
                                        }
                                        onSearch={this.fetchTeams}
                                        showSearch
                                    >
                                        {teams.map((item, index) => (
                                            <Select.Option
                                                key={index}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="order_type_id"
                                label="Tipo de lançamento"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Tipo de lançamento"
                                >
                                    {orderTypes.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="obs"
                                label="Observação"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="signed"
                                label="Assinado"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Modal open={this.state.iFrame}>
                    <iframe src={this.state.iFrame} />
                </Modal>
            </UIDrawerForm>
        )
    }
}

export default Create
