import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Form, Modal, Row, Tabs } from 'antd'

import moment from 'moment'

import { UIDrawerForm } from '../../../components'
import { paymentService } from 'redux/services'
import { number_format } from 'helpers/phpjs'

const config = {
    externalName: 'franqueado',
}

class Show extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        external: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            id: 0,
            item: {},
        }
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
            item: {},
        })

        paymentService
            .show({ id })
            .then((response) => {
                let item = response.data

                this.setState({
                    isLoading: false,
                    item: item,
                })
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                    onOk: () => {
                        // Force close
                        return this.onClose()
                    },
                })
            })
    }

    resetFields = () => {
        this.setState({
            item: {},
        })
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    render() {
        const { visible } = this.props
        const { id, isLoading, item } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                showBtnSave={false}
                title={`Visualizar ${this.props.external ? config.externalName : 'registro'
                    } [${id}]`}
            >
                <Form layout="vertical">
                    <Tabs defaultActiveKey="general">
                        <Tabs.TabPane
                            forceRender
                            tab="Info. Gerais"
                            key="general"
                        >
                            <Row gutter={16}>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Franqueado">
                                        {item.order?.franchisee?.name ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Valor recebido">
                                        {item.value
                                            ? `R$ ${number_format(
                                                item.value,
                                                2,
                                                ',',
                                                '.'
                                            )}`
                                            : '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Recebido">
                                        {item.received
                                            ? 'Recebido'
                                            : 'Não recebido'}
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Recebido em">
                                        {moment(item.received_at).calendar()}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Criação">
                                        {moment(item.created_at).calendar()}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Última atualização">
                                        {moment(item.updated_at).calendar()}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Show
