import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Divider, Form, Modal, Row, Switch, Tabs } from 'antd'

import moment from 'moment'

import { classeService, orderService, trainingService } from '../../redux/services'

import { UIDrawerForm } from '../../components'
import { number_format } from '../../helpers/phpjs'
import Classes from './Classes'

const config = {
	externalName: 'franqueado',
}

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		external: PropTypes.bool,
	}

	constructor(props) {
		super(props)

		this.state = {
			isLoading: true,
			id: 0,
			item: {},
		}
	}

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id: id,
			item: {},
		})

		trainingService
			.show({ id })
			.then((response) => {
				let item = response.data

				this.setState({
					isLoading: false,
					item: item,
				})
			})
			.catch((data) => {
				Modal.error({
					title: 'Ocorreu um erro!',
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose()
					},
				})
			})
	}

	resetFields = () => {
		this.setState({
			item: {},
		})
	}

	onClose = () => {
		// Reset fields
		this.resetFields()

		// Callback
		this.props.onClose()
	}

	render() {
		const { visible } = this.props
		const { id, isLoading, item } = this.state

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				showBtnSave={false}
				title={`Visualizar ${this.props.external ? config.externalName : 'registro'
					} [${id}]`}
			>
				<Form layout="vertical">
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane
							forceRender
							tab="Info. Gerais"
							key="general"
						>
							<Row gutter={16}>
								<Col xl={12} sm={12}>
									<Form.Item label="Nome">
										{item.name ?? '-'}
									</Form.Item>
								</Col>
								<Col xl={12} sm={12}>
									<Form.Item label="Aulas">
										{item.classes?.length ?? '-'}
									</Form.Item>
								</Col>
							</Row>
							<Divider />
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item label="Criação">
										{moment(item.created_at).calendar()}
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item label="Última atualização">
										{moment(item.updated_at).calendar()}
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane tab="Aulas" key="payments">
							<Classes id={id} item={item} />
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Show
