function NotFound() {

	return (
		<div className="page-login">
			pagina nao encontrada
		</div>
	);
}

export default NotFound
