import React, { Component } from 'react'
import MDBox from 'components/MDBox'

// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import { UILayout } from 'components'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import ComplexStatisticsCardEdit from 'examples/Cards/StatisticsCards/ComplexStatisticsCardEdit'
import { dashboardService } from 'redux/services'
import { PieChart, } from '@rsuite/charts'
import { Button, Modal, Select } from 'antd'
import Card from '@mui/material/Card'
import MDTypography from 'components/MDTypography'
import { connect } from 'react-redux'
import moment from 'moment'
import { stageService } from 'redux/services'
import ModalFilters from './filters'
import { number_format } from 'helpers/phpjs'

class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: {},
            orders: [],
            stages: [],
            filtersModalVisible: false,
            // Filters
            totalFilters: 0,
            filters: {
                signed: null,
                created_at: null,
                stage_id: this.props.router?.location?.state?.stage_id ?? null,
                user_id: null,
                service_id: null,
            },
            series: [
                {
                    name: '',
                    data: [200, 330, 548, 740, 880, 990, 1100, 1380],
                },
            ],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: true,
                        distributed: true,
                        barHeight: '80%',
                        isFunnel: true,
                    },
                },
                colors: [
                    '#F44F5E',
                    '#E55A89',
                    '#D863B1',
                    '#CA6CD8',
                    '#B57BED',
                    '#8D95EB',
                    '#62ACEA',
                    '#4BC3E6',
                ],
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex]
                    },
                    dropShadow: {
                        enabled: true,
                    },
                },
                title: {
                    text: 'Pyramid Chart',
                    align: 'middle',
                },
                xaxis: {
                    categories: [
                        'Sweets',
                        'Processed Foods',
                        'Healthy Fats',
                        'Meat',
                        'Beans & Legumes',
                        'Dairy',
                        'Fruits & Vegetables',
                        'Grains',
                    ],
                },
                legend: {
                    show: false,
                },
            },
        }
    }

    componentDidMount() {
        this.fetchInitial()
    }

    fetchInitial = () => {
        const { filters } =
            this.state

        const { userData } = this.props

        this.setState({
            isLoading: true,
        })

        const data = {}

        if (filters.signed !== null) {
            data.signed = filters.signed
        }

        if (filters.stage_id !== null) {
            data.stage_id = filters.stage_id
        }


        if (filters.service_id !== null) {
            data.service_id = filters.service_id
        }

        if (filters.user_id !== null) {
            data.user_id = filters.user_id
        }

        if (filters.created_at) {
            data.created_at = [
                filters.created_at[0]
                    .clone()
                    .startOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
                filters.created_at[1]
                    .clone()
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
            ]
        }

        dashboardService
            .getAll(data)
            .then((response) => {
                this.setState({
                    data: response.data.data,
                    orders: response.data.data.orders,
                })

                return stageService.getAll()
            })
            .then((response) => {
                this.setState({
                    isLoading: false,
                    stages: response.data,
                })
            })
            .catch((data) => {
                this.setState(
                    {
                        isLoading: false,
                    },
                    () => {
                        Modal.error({
                            title: 'Ocorreu um erro!',
                            content: String(data),
                        })
                    }
                )
            })
    }

    greeting = () => {
        const hour = moment().hour();
        let day = "Bom dia";

        if (hour >= 19) {
            day = "Boa noite";
        }
        else if (hour >= 12) {
            day = "Boa tarde";
        }

        return `${this.getRole()} | Olá ${this.props.userData.name}, ${day}!`;
    };

    getRole = () => {
        switch (this.props.userData.role) {
            case "admin":
                return "Administrador";
            case "collaborator_admin":
                return "Colaborador Maion";
            case "collaborator":
                return "Colaborador";
            case "franchisee":
                return "Franqueado";
            case "consultant":
                return "Consultor";
            default:
                return "Administrador";
        }

    }

    onChartClick = (e) => {
        const { stages, filters } = this.state

        const stage = stages.find(stage => stage.name === e.data.name)

        this.props.router.navigate("/gestao", {
            state: {
                stage_id: stage.id,
                signed: filters.signed,
                created_at: filters.created_at,
                user_id: filters.user_id,
                service_id: filters.service_id,
            }
        })
    }


    /**
   * Filter
   */
    filtersOpen = () => {
        this.setState({ filtersModalVisible: true })

        // On open screen
        this.filtersScreen.onOpen({ ...this.state.filters })
    }

    filtersOnClose = () => {
        this.setState({ filtersModalVisible: false })
    }

    filtersOnComplete = (filters) => {
        this.setState({ filtersModalVisible: false })

        this.setState(
            {
                totalFilters: Object.keys(filters).filter(
                    (key) =>
                        filters.hasOwnProperty(key) && filters[key] !== null
                ).length,
                filters: filters,
            },
            () => {
                // Fecth all
                this.fetchInitial(true)
            }
        )
    }

    render() {
        const { data, orders } = this.state
        const { userData } = this.props

        return (
            <UILayout>
                <h3 className="page-title">{this.greeting()}</h3>
                <MDBox py={3} pt={7}>
                    <div className='dashboard-items'>
                        <div className='chart-container'>
                            <Card className='chart-container-card'>
                                <MDBox
                                    display="flex"
                                    justifyContent="space-between"
                                    pt={1}
                                    px={2}
                                >
                                    <MDBox
                                        variant="gradient"
                                        bgColor={'dark'}
                                        color={'dark'}
                                        coloredShadow={'dark'}
                                        borderRadius="xl"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        width="4rem"
                                        height="4rem"
                                        mt={-3}
                                    >
                                        <i
                                            className="fas fa-usd-square"
                                            color="#ffffff"
                                            style={{ color: 'white' }}
                                        ></i>
                                    </MDBox>
                                    <MDBox display="flex">
                                        <MDBox textAlign="right" lineHeight={1.25} mr={(userData.role === "admin" || userData.role === "collaborator_admin") ? 5 : 0}>
                                            <MDTypography
                                                variant="button"
                                                fontWeight="light"
                                                color="text"
                                            >
                                                Oportunidades
                                            </MDTypography>
                                            <MDTypography variant="h4">
                                                {data.orders_total}
                                            </MDTypography>
                                        </MDBox>
                                        {(userData.role === "admin" || userData.role === "collaborator_admin") &&
                                            <Button onClick={this.filtersOpen} icon={<i className="fas fa-funnel-dollar"></i>} />
                                        }
                                    </MDBox>
                                </MDBox>
                                <PieChart
                                    height={350}
                                    locale={{ emptyMessage: "Nenhum dado encontrado", loading: "Carregando..." }}
                                    data={orders}
                                    onEvents={{ "click": this.onChartClick }}
                                    legend
                                    startAngle={210}
                                    legendHoverLink
                                    bottom={80}

                                    tooltip={{
                                        trigger: 'item',
                                        triggerOn: "click",
                                        valueFormatter: (value) => `${value} | ${(value * 100 / data.orders_total).toFixed(0)}%`
                                    }}
                                />
                            </Card>
                        </div>
                        <div className='items-columns'>
                            <MDBox mb={5}>
                                <ComplexStatisticsCardEdit
                                    color="dark"
                                    icon="paid"
                                    title="Créditos"
                                    data={
                                        [
                                            {
                                                title: "Estimado",
                                                value: `R$ ${number_format(data.total_estimated, 2, ',', '.')}`
                                            },
                                            {
                                                title: "Apurado",
                                                value: `R$ ${number_format(data.total_credit, 2, ',', '.')}`
                                            },
                                            {
                                                title: "Recuperado",
                                                value: `R$ ${number_format(data.payments, 2, ',', '.')}`
                                            },
                                        ]
                                    }
                                />
                            </MDBox>
                            <MDBox mb={5}>
                                <ComplexStatisticsCard
                                    color="dark"
                                    icon="people"
                                    title="Clientes"
                                    count={data?.customer?.total}
                                    percentage={{
                                        color: 'success',
                                        amount: `+ ${data.customer?.lastMonth ?? 0
                                            }`,
                                        label: 'no ultimo mês',
                                    }}
                                />
                            </MDBox>
                            {(userData.role === "admin" || userData.role === "collaborator_admin") && (
                                <MDBox>
                                    <ComplexStatisticsCard
                                        icon="leaderboard"
                                        title="Franqueados"
                                        count={data?.franchisee?.total}
                                        percentage={{
                                            color: 'success',
                                            amount: `+ ${data.franchisee?.lastMonth ?? 0
                                                }`,
                                            label: 'no ultimo mês',
                                        }}
                                    />
                                </MDBox>
                            )}
                        </div>
                    </div>
                </MDBox>
                <ModalFilters
                    ref={(el) => (this.filtersScreen = el)}
                    visible={this.state.filtersModalVisible}
                    onComplete={this.filtersOnComplete}
                    onClose={this.filtersOnClose}
                />
            </UILayout>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        userData: state.auth.userData,
    }
}

export default connect(mapStateToProps, null)(Dashboard)
