import { api } from "./../../config/api";

const basePath = "users";

/**
 * Resend password
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const resendPassword = (options) => {
    return api.post(`${basePath}/resend-password/${options.id}`, options);
};
