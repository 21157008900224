import React, { Component } from 'react'
import axios from 'axios'
import * as PropTypes from 'prop-types'
import {
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
} from 'antd'

import { customerService, franchiseeService, taxRegimeService } from './../../redux/services'

import { UIDrawerForm, UIInputMask } from './../../components'
import { PatternFormat } from 'react-number-format'
import moment from 'moment'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Edit extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isSending: false,
            id: 0,
            item: null,
            taxRegimes: [],
            errors: [],
            franchiseesLoading: false,
            franchisees: [],
            searchMode: "Franqueado",
            armorType: "Nao",
            searchingCompany: false
        }

        this._axiosCancelToken = null
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
        })

        taxRegimeService
            .getAll()
            .then((response) => {
                this.setState({
                    taxRegimes: response.data.data,
                    isLoading: false,
                })


                return customerService.show({ id })
            })
            .then((response) => {
                let item = response.data

                this.setState(
                    {
                        isLoading: false,
                        item: item,
                    },
                    () => {
                        // Fill form
                        this.fillForm(item)
                    }
                )
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })


    }

    fillForm = (data) => {
        const franchisee = data.user[0]

        if (franchisee) {
            if (franchisee?.user_id === 1) {
                this.setState({
                    armorType: "Maion"
                })
            } else {
                this.setState({
                    armorType: "Sim"
                })
            }


            if (franchisee?.role === "consultant") {
                this.setState({
                    searchMode: "Consultor"
                })
            } else {
                this.setState({
                    searchMode: "Franqueado"
                })
            }
        } else {
            this.setState({
                armorType: "Nao"
            })
        }

        this.form.setFieldsValue({
            name: data.name,
            company_name: data.company_name,
            email: data.email,
            document: data.document,
            phone: data.phone,
            birth: moment(data.birth),
            corporate_name: data.corporate_name,
            contact_name: data.contact_name,
            tax_regime: data.tax_regime,
            user_id: franchisee?.company_name ?? undefined,
            obs: data.obs
        })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const { id, item } = this.state
        const franchisee = item.user[0]

        const data = {
            ...values,
        }

        // id
        data.id = id

        if (data.user_id === franchisee?.company_name) {
            delete data.user_id
        }

        if (this.state.armorType === "Maion") {
            data.maion_customer = true
        }

        customerService
            .edit(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                    searchMode: "Franqueado",
                    armorType: "Nao",
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro atualizado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    onSearch = (value) => {
        if (!value || value.length === 0) return

        this.setState({
            searchingCompany: true
        })

        customerService
            .consultCnpj({ cnpj: value })
            .then((response) => {
                const { data } = response

                this.form.setFieldsValue({
                    company_name:
                        data.estabelecimento?.nome_fantasia ??
                        data.razao_social,
                    corporate_name: data.razao_social,
                    contact_name: '',
                    phone:
                        data.estabelecimento?.ddd1 +
                        data.estabelecimento?.telefone1,
                    email: data.estabelecimento?.email,
                })

                this.setState({
                    searchingCompany: false
                })
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                    searchingCompany: false
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchFranchisees = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                franchiseesLoading: false,
                franchisees: [],
            })

            return false
        }

        this.setState({
            franchiseesLoading: true,
        })

        franchiseeService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    franchiseesLoading: false,
                    franchisees: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    franchiseesLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchConsultants = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                franchiseesLoading: false,
                franchisees: [],
            })

            return false
        }

        this.setState({
            franchiseesLoading: true,
        })

        consultantService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    franchiseesLoading: false,
                    franchisees: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    franchiseesLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    setFieldsError = ({ messages }) => {
        if (!messages?.errors) return

        this.setState({
            errors: messages.errors,
        })
    }

    render() {
        const { visible, userData } = this.props

        const {
            id,
            isLoading,
            isSending,
            franchiseesLoading,
            franchisees,
            taxRegimes,
            armorType,
            searchingCompany
        } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title={`Editar registro [${id}]`}
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                    initialValues={{
                        is_active: true,
                        document_type: 'cpf',
                        blind_company: true
                    }}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="document"
                                label="CNPJ"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                                validateStatus={
                                    this.setFieldsError('document')?.hasError
                                }
                                help={this.setFieldsError('document')?.message}
                            >
                                <UIInputMask
                                    allowClear
                                    loading={searchingCompany}
                                    mask="00.000.000/0000-00"
                                    onSearch={this.onSearch}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="company_name"
                                label="Nome da empresa"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="corporate_name"
                                label="Razão social"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="contact_name"
                                label="Nome do responsável"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="phone"
                                label="Telefone"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <PatternFormat
                                    className="ant-input  ant-input-outlined"
                                    format="(##) #####-####"
                                    allowEmptyFormatting
                                    mask="_"
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="email"
                                label="E-mail"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                    {
                                        type: 'email',
                                    },
                                ]}
                            >
                                <Input type="email" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="tax_regime"
                                label="Regime tributário"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Selecione o Regime tributário"
                                >
                                    {taxRegimes.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {(userData.role === "admin" || userData.role === "collaborator_admin") && (
                            <Col xl={12} sm={12}>
                                <Form.Item
                                    label="Blindar cliente?"
                                    hasFeedback
                                >
                                    <Radio.Group
                                        value={this.state.armorType}
                                        onChange={e => this.setState({ armorType: e.target.value })}
                                        options={[
                                            {
                                                label: 'Não',
                                                value: "Nao",
                                            },
                                            {
                                                label: 'Maion',
                                                value: "Maion",
                                            },
                                            {
                                                label: 'Franqueado/Consultor',
                                                value: "Sim",
                                            },
                                        ]} />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>

                    {this.state.armorType === "Sim" && (
                        <Row gutter={16}>
                            {(userData.role === "admin" || userData.role === "collaborator_admin") ? (
                                <Col xl={12} sm={12}>
                                    <Form.Item
                                        name="user_id"
                                        label={
                                            <Radio.Group
                                                value={this.state.searchMode}
                                                onChange={e => this.setState({ searchMode: e.target.value })}
                                                options={[
                                                    {
                                                        label: 'Franqueado',
                                                        value: "Franqueado",
                                                    },
                                                    {
                                                        label: 'Consultor',
                                                        value: "Consultor",
                                                    },
                                                ]} />
                                        }
                                        hasFeedback
                                    >
                                        {this.state.searchMode === "Maion" ? (
                                            <Input
                                                value="MaionTax"
                                                placeholder='Maion Tax'
                                                disabled
                                            />
                                        ) : (
                                            <Select
                                                filterOption={false}
                                                allowClear
                                                placeholder={`Pesquise o ${this.state.searchMode}`}
                                                notFoundContent={
                                                    franchiseesLoading ? (
                                                        <Spin
                                                            indicator={
                                                                <i className="fad fa-spinner-third fa-spin" />
                                                            }
                                                        />
                                                    ) : null
                                                }
                                                onSearch={this.state.searchMode === "Franqueado" ? this.fetchFranchisees : this.fetchConsultants}
                                                showSearch
                                            >
                                                {franchisees.map((item, index) => (
                                                    <Select.Option
                                                        key={index}
                                                        value={item.id}
                                                    >
                                                        {item.company_name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Form.Item
                                    name="blind_company"
                                    label="Deseja blindar cliente?"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            )}
                        </Row>
                    )}
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="obs"
                                label="Observação"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />
                    <Form.Item
                        name="is_active"
                        label="Ativo"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Edit
