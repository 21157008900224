import React, { Component } from 'react'
import axios from 'axios'
import * as PropTypes from 'prop-types'
import {
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
} from 'antd'

import {
    consultantService,
    franchiseeService,
    orderService,
    orderTypeService,
    serviceService,
    stageService,
    teamService,
} from './../../redux/services'

import { UIDatePicker, UIDrawerForm } from './../../components'
import UIInputNumber from 'components/InputNumber'
import moment from 'moment'
import { parseFormattedNumber } from 'helpers/phpjs'
import { NumericFormat } from 'react-number-format'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Edit extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isSending: false,
            id: 0,
            data: {},
            documentType: 'cpf',
            customersLoading: false,
            customers: [],
            franchiseesLoading: false,
            franchisees: [],
            services: [],
            stages: [],
            teams: [],
            orderTypes: [],
            services: [],
            franchiseesLoading: false,
            franchisees: [],

            honorary: 0,
            estimated_credit: null,
            credit: null,

            current_stage: null,
            stage_id: null,
            searchMode: this.props.userData.role === "franchisee" ? "my_company" : "Franqueado"
        }

        this._axiosCancelToken = null
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
        })

        Promise.all([
            serviceService.getAll(),
            stageService.getAll(),
            teamService.getAll(),
            orderTypeService.getAll(),
        ])
            .then(
                ([
                    serviceResponse,
                    stageResponse,
                    teamResponse,
                    orderTypeResponse,
                ]) => {
                    this.setState(
                        {
                            services: serviceResponse.data.data,
                            stages: stageResponse.data,
                            teams: teamResponse.data.data,
                            orderTypes: orderTypeResponse.data,
                        },
                        () => {
                            orderService
                                .show({ id })
                                .then((response) => {
                                    this.setState(
                                        {
                                            isLoading: false,
                                            data: response.data,
                                            current_stage: response.data.stage_id,
                                            stage_id: response.data.stage_id,
                                            searchMode: this.props.userData.id === response.data.franchisee?.id ? "my_company" : "Consultor"
                                        },
                                        () => {
                                            this.fillForm(response.data)
                                        }
                                    )
                                })
                                .catch((data) => {
                                    Modal.error({
                                        title: 'Ocorreu um erro!',
                                        content: String(data),
                                    })
                                })
                        }
                    )
                }
            )
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fillForm = (data) => {
        this.form.setFieldsValue({
            stage_id: data.stage_id,
            service_id: data.service?.id,
            customer_id: data.customer?.company_name,
            user_id: data.franchisee?.name,
            honorary: data.honorary,
            signed: data.signed,
            estimated_credit: data.estimated_credit,
            credit: data.credit,
            completion_forecast: data.completion_forecast
                ? moment(data.completion_forecast)
                : null,
            finish_at: data.finish_at ? moment(data.finish_at) : null,
            team_id: data.team_id,
            order_type_id: data.order_type_id,
            obs: data.obs,
        })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    fetchFranchisees = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                franchiseesLoading: false,
                franchisees: [],
            })

            return false
        }

        this.setState({
            franchiseesLoading: true,
        })

        franchiseeService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    franchiseesLoading: false,
                    franchisees: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    franchiseesLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchConsultants = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                franchiseesLoading: false,
                franchisees: [],
            })

            return false
        }

        this.setState({
            franchiseesLoading: true,
        })

        consultantService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    franchiseesLoading: false,
                    franchisees: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    franchiseesLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const { id } = this.state

        const data = {
            ...values,
            estimated_credit: this.state.estimated_credit,
            credit: this.state.credit,
            honorary: this.state.honorary,
        }

        if (values.completion_forecast) {
            data.completion_forecast = moment(
                values.completion_forecast
            ).format('YYYY-MM-DD')
        }

        if (values.finish_at) {
            data.finish_at = moment(values.finish_at).format('YYYY-MM-DD')
        }

        delete data.customer_id

        if (this.props.userData.role === "franchisee" && values.user_id === data.franchisee?.id) {
            delete data.user_id
        }

        if (typeof values.user_id === "string") {
            delete data.user_id
        }

        // id
        data.id = id

        orderService
            .edit(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro atualizado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { visible, userData } = this.props

        const isAdminAccess =
            userData.role === "admin" || userData.role === "collaborator_admin"

        const {
            id,
            isLoading,
            isSending,
            stages,
            teams,
            orderTypes,
            services,
            franchiseesLoading,
            franchisees
        } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title={`Editar registro [${id}]`}
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="customer_id"
                                label="Cliente"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="service_id"
                                label="Serviço"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Serviço"
                                    disabled={userData.role !== "admin" && userData.role !== "collaborator_admin"}
                                >
                                    {services.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="stage_id"
                                label="Etapa"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Etapa"
                                    value={this.state.stage_id}
                                    onChange={(e) => this.setState({ current_stage: e })}
                                >
                                    {stages.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {this.state.stage_id !== this.state.current_stage && <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="stage_comment"
                                label="Comentário"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>}
                    <Row gutter={16}>
                        {(userData.role === "franchisee") ? (
                            <Col xl={12} sm={12}>
                                <Form.Item
                                    name="user_id"
                                    label={
                                        <Radio.Group
                                            value={this.state.searchMode}
                                            onChange={e => this.setState({ searchMode: e.target.value })}
                                            options={[
                                                {
                                                    label: 'Minha empresa',
                                                    value: "my_company",
                                                },
                                                {
                                                    label: 'Consultor',
                                                    value: "Consultor",
                                                },
                                            ]} />
                                    }
                                    hasFeedback
                                >
                                    <Select
                                        filterOption={false}
                                        allowClear
                                        placeholder={`Pesquise o Consultor`}
                                        disabled={this.state.searchMode === "my_company"}
                                        notFoundContent={
                                            franchiseesLoading ? (
                                                <Spin
                                                    indicator={
                                                        <i className="fad fa-spinner-third fa-spin" />
                                                    }
                                                />
                                            ) : null
                                        }
                                        onSearch={this.state.searchMode === "Franqueado" ? this.fetchFranchisees : this.fetchConsultants}
                                        showSearch
                                        options={franchisees.map((item) => ({
                                            label: item.name,
                                            value: item.id,
                                            index: item.id
                                        }))}
                                    />
                                </Form.Item>
                            </Col>
                        ) : (
                            <Col xl={12} sm={12}>
                                <Form.Item
                                    name="user_id"
                                    label="Franqueado"
                                    hasFeedback
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        )}

                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="honorary"
                                label="Honorário %"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <NumericFormat
                                    className="ant-input ant-input-outlined"
                                    suffix="%"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    max={100}
                                    min={1}
                                    value={this.state.honorary}
                                    onValueChange={({ floatValue }) => {
                                        this.setState({
                                            honorary: floatValue,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="estimated_credit"
                                label="Crédito estimado"
                                hasFeedback
                            >
                                <NumericFormat
                                    disabled={!isAdminAccess}
                                    className="ant-input ant-input-outlined"
                                    prefix="R$"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    value={this.state.estimated_credit}
                                    onValueChange={({ floatValue }) => {
                                        this.setState({
                                            estimated_credit: floatValue,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="credit"
                                label="Crédito apurado"
                                hasFeedback
                            >
                                <NumericFormat
                                    disabled={!isAdminAccess}
                                    className="ant-input ant-input-outlined"
                                    prefix="R$"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    value={this.state.credit}
                                    onValueChange={({ floatValue }) => {
                                        this.setState({
                                            credit: floatValue,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="completion_forecast"
                                label="Previsão de conclusão"
                                hasFeedback
                            >
                                <UIDatePicker
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY"
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="finish_at"
                                label="Concluído em"
                                hasFeedback
                            >
                                <UIDatePicker
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="order_type_id"
                                label="Tipo de lançamento"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Tipo de lançamento"
                                >
                                    {orderTypes.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="team_id"
                                label="Time"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Time"
                                >
                                    {teams.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="obs"
                                label="Observação"
                                hasFeedback
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="signed"
                                label="Assinado"
                                valuePropName="checked"
                            >
                                <Switch disabled={this.state.data.signed} />
                            </Form.Item>
                        </Col>
                        {this.state.data.signed_at && (
                            <Col xl={12} sm={12}>
                                <Form.Item label="Assinado em">
                                    {moment(
                                        this.state.data.signed_at
                                    ).calendar()}
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Edit
