import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Button, DatePicker, Form, Modal, Radio, Select, Spin, Tag } from 'antd'

import moment from 'moment'
import { stageService } from 'redux/services'
import { UIDatePicker } from 'components'
import { franchiseeService } from 'redux/services'
import axios from 'axios'
import { API_ERRO_TYPE_CANCEL } from 'config/general'
import { connect } from 'react-redux'
import { consultantService } from 'redux/services'
import { serviceService } from 'redux/services'

class Filters extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.filtersClean = {
            signed: null,
            created_at: null,
            stage_id: null,
            user_id: null,
            service_id: null,
        }

        this.state = {
            filters: {
                ...this.filtersClean,
            },
            isLoading: true,
            stages: [],

            franchiseesLoading: false,
            franchisees: [],

            services: [],
            searchMode: "Franqueado",
        }
    }

    onOpen = (filters) => {
        this.setState(
            {
                filters: filters,
                isLoading: true,
            },
            () => {
                Promise.all([
                    stageService
                        .getAll(),
                    serviceService
                        .getAll()
                ]).then(([stagesResponse, servicesResponse]) => {
                    this.setState(
                        {
                            isLoading: false,
                            stages: stagesResponse.data,
                            services: servicesResponse.data.data,
                        }
                    )
                })
                    .catch((data) => {
                        Modal.error({
                            title: 'Ocorreu um erro!',
                            content: String(data),
                        })
                    })
            }
        )
    }

    fetchFranchisees = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                franchiseesLoading: false,
                franchisees: [],
            })

            return false
        }

        this.setState({
            franchiseesLoading: true,
        })

        franchiseeService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    franchiseesLoading: false,
                    franchisees: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    franchiseesLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchConsultants = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                franchiseesLoading: false,
                franchisees: [],
            })

            return false
        }

        this.setState({
            franchiseesLoading: true,
        })

        consultantService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    franchiseesLoading: false,
                    franchisees: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    franchiseesLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    cleanFilters = () => {
        this.setState(
            {
                filters: this.filtersClean,
            },
            () => {
                // Callback
                this.props.onComplete({ ...this.state.filters })
            }
        )
    }

    onClose = () => {
        // Callback
        this.props.onClose()
    }

    filtersOnConfirm = () => {
        // Callback
        this.props.onComplete({ ...this.state.filters })
    }

    setFilter = (name, value) => {
        this.setState((state) => ({
            filters: {
                ...state.filters,
                [name]: value,
            },
        }))
    }

    render() {
        const { visible } = this.props

        const { services, filters, stages, isLoading, franchisees, franchiseesLoading } = this.state

        return (
            <Modal
                open={visible}
                title="Filtrar"
                centered={true}
                destroyOnClose={true}
                maskClosable={true}
                width={900}
                okText="Aplicar"
                onCancel={this.onClose}
                onOk={this.filtersOnConfirm}
                className="modal-filters"
                footer={[
                    <Button key="back" type="link" onClick={this.cleanFilters}>
                        Excluir filtros
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={this.filtersOnConfirm}
                    >
                        Aplicar
                    </Button>,
                ]}
            >
                {(this.props.userData.role === "admin" || this.props.userData.role === "collaborator_admin") && (
                    <div className="filter-group">
                        <div className="filter-group-title">
                            <h3>
                                <Radio.Group
                                    value={this.state.searchMode}
                                    onChange={e => this.setState({ searchMode: e.target.value })}
                                    options={[
                                        {
                                            label: 'Franqueado',
                                            value: "Franqueado",
                                        },
                                        {
                                            label: 'Consultor',
                                            value: "Consultor",
                                        },
                                    ]}
                                />
                            </h3>
                        </div>
                        <div
                            className="filter-group-filters"
                            style={{ paddingBottom: 0 }}
                        >
                            <Form.Item>
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder={`Pesquise o ${this.state.searchMode}`}
                                    notFoundContent={
                                        franchiseesLoading ? (
                                            <Spin
                                                indicator={
                                                    <i className="fad fa-spinner-third fa-spin" />
                                                }
                                            />
                                        ) : null
                                    }
                                    onSearch={this.state.searchMode === "Franqueado" ? this.fetchFranchisees : this.fetchConsultants}
                                    showSearch
                                    onChange={(value) =>
                                        this.setFilter('user_id', value ?? null)
                                    }
                                >
                                    {franchisees.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {this.state.searchMode === "Franqueado" ? item.company_name : item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                )}
                <div className="filter-group">
                    <div className="filter-group-title">
                        <h3>Etapa</h3>
                    </div>
                    <div
                        className="filter-group-filters"
                        style={{ paddingBottom: 0 }}
                    >
                        <Form.Item>
                            <Select
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (typeof option.children === 'string'
                                        ? option.children
                                        : option.children.props.children
                                    )
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear
                                placeholder="Selecione a etapa"
                                notFoundContent={
                                    isLoading ? (
                                        <Spin
                                            indicator={
                                                <i className="fad fa-spinner-third fa-spin" />
                                            }
                                        />
                                    ) : null
                                }
                                onChange={(value) =>
                                    this.setFilter('stage_id', value ?? null)
                                }
                                showSearch
                                value={this.state.filters.stage_id}
                            >
                                {stages.map((item, index) => (
                                    <Select.Option
                                        key={index}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className="filter-group">
                    <div className="filter-group-title">
                        <h3>Serviço</h3>
                    </div>
                    <div
                        className="filter-group-filters"
                        style={{ paddingBottom: 0 }}
                    >
                        <Form.Item>
                            <Select
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (typeof option.children === 'string'
                                        ? option.children
                                        : option.children.props.children
                                    )
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear
                                placeholder="Selecione o serviço"
                                notFoundContent={
                                    isLoading ? (
                                        <Spin
                                            indicator={
                                                <i className="fad fa-spinner-third fa-spin" />
                                            }
                                        />
                                    ) : null
                                }
                                onChange={(value) =>
                                    this.setFilter('service_id', value ?? null)
                                }
                                showSearch
                                value={this.state.filters.service_id}
                            >
                                {services.map((item, index) => (
                                    <Select.Option
                                        key={index}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className="filter-group">
                    <div
                        className="filter-group-title"
                        style={{ paddingTop: 0 }}
                    >
                        <h3>Assinado</h3>
                    </div>
                    <div
                        className="filter-group-filters"
                        style={{ paddingBottom: 5 }}
                    >
                        <div className="filter-group-radios">
                            <div className="filter-group-radio">
                                <Radio
                                    onChange={(e) =>
                                        this.setFilter('signed', null)
                                    }
                                    checked={filters.signed === null}
                                >
                                    Todos
                                </Radio>
                            </div>
                            <div className="filter-group-radio">
                                <Radio
                                    onChange={(e) =>
                                        this.setFilter('signed', 1)
                                    }
                                    checked={filters.signed === 1}
                                >
                                    Sim
                                </Radio>
                            </div>
                            <div className="filter-group-radio">
                                <Radio
                                    onChange={(e) =>
                                        this.setFilter('signed', 0)
                                    }
                                    checked={filters.signed === 0}
                                >
                                    Não
                                </Radio>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filter-group">
                    <div className="filter-group-title">
                        <h3>Criação</h3>
                    </div>
                    <div
                        className="filter-group-filters"
                        style={{ paddingBottom: 0 }}
                    >
                        <Form.Item>
                            <UIDatePicker.RangePicker
                                format="DD/MM/YYYY"
                                value={filters.created_at}
                                onChange={(date, dateString) =>
                                    this.setFilter('created_at', date ?? null)
                                }
                                disabledDate={(currentDate) =>
                                    currentDate.isAfter(moment(), 'day')
                                }
                            />
                        </Form.Item>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.auth.userData,
    }
}


export default connect(mapStateToProps, null, null, { forwardRef: true })(Filters)

