import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Form, Modal, Row, Switch, Tabs } from 'antd'

import moment from 'moment'

import {
    customerService,
    franchiseeService,
    simulationService,
} from '../../redux/services'

import { UIDrawerForm } from '../../components'
import { number_format } from '../../helpers/phpjs'

const config = {
    externalName: 'franqueado',
}

class Show extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        external: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            id: 0,
            item: {},
            user: {},
        }
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
            item: {},
        })

        customerService
            .show({ id })
            .then((response) => {
                let item = response.data
                let user = {}

                if (item.user[0]) {
                    user = item.user[0]
                }

                this.setState({
                    isLoading: false,
                    item: item,
                    user,
                })
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                    onOk: () => {
                        // Force close
                        return this.onClose()
                    },
                })
            })
    }

    resetFields = () => {
        this.setState({
            item: {},
        })
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    render() {
        const { visible } = this.props
        const { id, isLoading, item, user } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                showBtnSave={false}
                title={`Visualizar ${
                    this.props.external ? config.externalName : 'registro'
                } [${id}]`}
            >
                <Form layout="vertical">
                    <Tabs defaultActiveKey="general">
                        <Tabs.TabPane
                            forceRender
                            tab="Info. Gerais"
                            key="general"
                        >
                            <Row gutter={16}>
                                <Col xs={24} sm={24}>
                                    <Form.Item label="Nome da empresa">
                                        {item.company_name ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Razão social">
                                        {item.corporate_name ?? '-'}
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Responsável da empresa">
                                        {item.contact_name ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}></Row>
                            <Row gutter={16}>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="CNPJ">
                                        {item.document ?? '-'}
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Telefone">
                                        {item.phone ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="Regime tributário">
                                        {item.tax?.name ?? '-'}
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12}>
                                    <Form.Item label="E-mail">
                                        {item.email ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} sm={24}>
                                    <Form.Item label="Observação">
                                        {item.obs ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Criação">
                                        {moment(item.created_at).calendar()}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Última atualização">
                                        {moment(item.updated_at).calendar()}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        {user.armor_time && (
                            <Tabs.TabPane
                                forceRender
                                tab="Blindagem"
                                key="armor"
                            >
                                <Row gutter={16}>
                                    <Col xs={24} sm={24}>
                                        <Form.Item label="Blindado por">
                                            {user?.name ?? '-'}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xs={12} sm={12}>
                                        <Form.Item label="Razão social">
                                            {user?.company_name ?? '-'}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12}>
                                        <Form.Item label="Telefone">
                                            {user?.phone ?? '-'}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col xs={12} sm={12}>
                                        <Form.Item label="Blindado até">
                                            {moment(user.armor_time).calendar()}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12}>
                                        <Form.Item label="Status da blindagem">
                                            Ativo
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                        )}
                    </Tabs>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Show
