import { appendToFormData } from "helpers/form";
import { api } from "../../config/api";

const basePath = "classes";

/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAll = (options) => {
    const options_default = {};

    // Merge config
    options = Object.assign({}, options_default, options);

    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("page")) {
        params.push(`page=${options.page}`);
    }

    if (options.hasOwnProperty("limit")) {
        params.push(`limit=${options.limit}`);
    }

    if (options.hasOwnProperty("search")) {
        params.push(`search=${options.search}`);
    }

    if (options.hasOwnProperty("orderBy")) {
        params.push(`orderBy=${options.orderBy}`);
    }

    if (options.hasOwnProperty("is_active")) {
        params.push(`is_active=${options.is_active}`);
    }

    if (options.hasOwnProperty("id")) {
        params.push(`id=${options.id}`);
    }


    if (options.hasOwnProperty("created_at")) {
        options.created_at.forEach((item, index) => {
            params.push(`created_at[${index}]=${item}`);
        });
    }

    if (params.length) {
        params_qs = `?${params.join("&")}`;
    }

    if (options.exportItems) {
        return api.get(`${basePath}/export${params_qs}`);
    }
    else {
        return api.get(`${basePath}${params_qs}`);
    }
};

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const create = (options, config) => {
    const formData = new FormData();

    formData.append("name", options.name);
    formData.append("training_id", options.training_id);
    formData.append("order", options.order);
    formData.append('file', options.file);
    formData.append('description', options.description);


    return api.post(`${basePath}`, formData, config)
}

/**
 * Edit
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
    return api.put(`${basePath}/${options.id}`, options)
}

/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const show = (options) => {
    return api.get(`${basePath}/${options.id}`)
}

/**
 * Delete
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const destroy = (options) => {
    return api.delete(`${basePath}/${options.id}`)
}

